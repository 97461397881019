@import 'settings/variable'; //themes' colors

//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding'; // styles of base elements
@import './base/typography'; // base styles of h1-h6, p, span

//components...
@import 'component/btn';
@import './component/card';
@import './component/check-box';
@import './component/sidebar';
@import './component/topbar';
@import './component/load';
@import './component/form';

//pages...
@import 'containers/account';

//objects...
@import 'objects/layout.scss';
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');

* {
  // font-family: 'Raleway', sans-serif;
  font-family: arial;
}

#paypalCredit {
  display: none;
}

.paypalbtn div {
  margin: 0 !important
}

.step_branding {
  height: 180px !important;
}

.step_brandings {
  margin-bottom: 25px;
}

.date-picker {
  width: 100%;
  float: right;
  padding: 0 16px 16px;
}

.overlayIntro {
  z-index: 99999 !important;
}

.date-picker .daterangepickercontainer {
  float: left;
}

.select_date {
  font-size: 15px;
  padding: 5px 10px 5px 0px;
  float: left;
  font-weight: bold;
}

.company_logo {
  width: 240px !important;
  background: #fff;
  height: 103px;
  text-align: center;
  position: absolute;
  // bottom: 55px;
}

.company_logo_img {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  height: 87px;
  width: auto;
  padding: 10px;
  margin: auto;
}

.csv-reader-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 117px;
}

.csv-input {
  margin-top: -29px !important;
  background: transparent !important;
  padding-bottom: 57px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 117px !important;
  cursor: pointer;
}

.tooltips {
  width: 15px !important;
  margin-left: 5px;
  cursor: pointer;
}

.form__form-group-field label {
  width: auto !important;
  margin: 0 !important;
}

.bold-textt {
  font-size: 13px;
  width: 100%;
  float: left;
  border-bottom: solid 2px #d9d9d9;
  padding: 0 10px 10px 0;
  margin-bottom: 15px;
  color: #6a6a6a !important;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  color: #000;
  font-weight: bold;
}

.dashboard_top {
  padding: 0 !important;
}

.account__wrapper {
  float: none !important;
  margin: auto !important;
  width: 600px !important;
}

.account__wrapper_1 {
  float: right;
  width: 50%;
}


.account__card {
  padding: 80px 10% !important;
}

.form input {
  border-bottom: 1px solid #39435e !important;
}

.account__card_inner {
  border: solid 1px #d6d6d6 !important;
  padding: 25px 40px;
  border-radius: 5px;
}

.account__logo {
  margin: 0;
}

.signup___card {
  box-shadow: 0px 2px 6px #828282;
  margin-top: 30px !important;
}

.account__logo img {
  width: 50%;
  margin: 0;
}

.search {
  width: 350px;
  float: right;
}

.search input {
  width: 80%;
  float: left;
  border-radius: 10px 0 0 10px;
}

.search button {
  width: 20%;
  float: right;
}

.search .btn {
  padding: 7.5px;
  border-radius: 0 10px 10px 0;
}

.bold-text {
  float: left;
}

.general--table .Instructions .table_div {
  height: 320px;
  width: 100%;
  float: left;
  overflow: auto;
}

.searchbox {
  float: left;
  border: solid 1px #ccc !important;
  padding: 11px 8px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.btn {
  cursor: pointer !important;
}

.createprogressspan:last-child {
  border-bottom: none !important
}

.firstlastname .left {
  width: 48%;
  float: left;
}

.firstlastname .right {
  width: 48%;
  float: right;
}

.signup___card {
  padding: 20px !important;
  width: 480px;
  margin: auto;
}

.signupform .form__form-group-field {
  width: 100% !important;
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 0px;
}

.signupform .form__form-group-field input {
  border: none !important;
}

.signupbtns_active {
  background-color: #39435e;
  border-color: #39435e;
  width: 100%;
  margin: 0;
  color: #fff;
}

.signupbtns_active:hover {
  color: #fff;
}

.signup_option_div {
  position: relative;
  width: 100%;
  height: 50px;
}

.signup_option {
  width: 112px;
  background: #fff;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto !important;
  color: #39435e !important;
  text-align: center;
}

.fb_btn {
  background: transparent;
  border: none;
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 0 auto;
}

.fb_btn:hover {
  opacity: 0.7;
}

.signup_option_hr {
  // background: #ccc;
  width: 100%;
  height: 1px;
  margin-top: 30px;
  float: left;
}

.account__left {
  height: 100%;
}

.account__left img {
  height: 100%;
}

.already_btn {
  text-align: center;
  width: 100%;
  float: left;
  padding: 20px 0 0 0;
  font-size: 12px;
  text-decoration: underline;
}

// .topbar__button
// {
//   background: #fff!important;
// }

.topbar {
  z-index: 1000;
  height: 0;
}

.topbar__left {
  width: 78% !important;
}

// .salesgraphs .recharts-wrapper
// {
//   width:75%!important;
// }

.Toastify__toast-container {
  margin-top: 50px !important;
}


.menuslider {
  width: 240px;
  background: #39435e;
  text-align: left;
  color: #fff;
  // padding-left: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  height: 80px;
}

.menuslider:hover {
  background: #39435e !important;
}

.market-div {
  padding: 0 !important;
  max-width: 44%;
  float: left;
  width: 44%;
  margin: 20px 27%;
  text-align: center;
}



.market-div h3,
.market-div p {
  // color: #000!important;
  font-weight: bold;
  margin-bottom: 30px;
}

.market-div p {
  font-size: 14px;
}

.heading_search {
  width: 186px !important;
  margin-top: 25px !important;
  margin-left: 5px !important;
}

.heading_search button {
  width: 100%;
  border: none;
  border-radius: 0 10px 10px 0;
}

.heading_search a {
  background-color: #dddddd;
  border-color: #dddddd;
  border-radius: 0 10px 10px 0;
}

.advertise_bud {
  float: left;
  border: solid 2px #f3f3f3;
  padding: 15px 20px;
  text-align: left;
  margin-top: 30px;
}

.advertise_bud h4 {
  font-weight: bold;
  margin-bottom: 25px;
}

.advertise_bud span {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 25px;
  float: left;
}

.advertise_bud button {
  background: #3d445a;
  border-radius: 5px;
  color: #fff;
  border: none;
  padding: 8px 10px;
}

.page-title {
  float: left;
  margin-bottom: 0 !important;
  padding-top: 20px !important;
}

.page-title {
  padding-bottom: 20px !important;
  font-size: 15px;
}


.btn-primary {
  background-color: #39435e !important;
  border-color: #39435e !important;
}

// .deleteBtn {
//   float: right;
//   padding: 3px 22px;
//   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,eaeaea+100 */
//   /* Old browsers */
//   background: red !important;
//   /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eaeaea', GradientType=0) !important;
//   /* IE6-9 */
//   border: solid 1px #d9d9d9 !important;
//   color: #ffffff !important;
//   margin-left: 25px;
//   margin-right: 0px;
//   border-radius: 8px !important;
// }

.addbtn {
  float: right;
  padding: 3px 22px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,eaeaea+100 */
  /* Old browsers */
  background: rgb(61, 68, 90) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eaeaea', GradientType=0) !important;
  /* IE6-9 */
  border: solid 1px #d9d9d9 !important;
  color: #ffffff !important;
  margin-left: 25px;
  margin-right: 0px;
  border-radius: 8px !important;
}

.deleteBtn {
  @extend .addbtn;
  background: red !important;
}

.addbtn a {
  color: #ffffff !important;
}

// .btn:hover
// {
//   color:#fff!important;
// }

.profile_img {
  width: 50px;
  float: left;
}

.profile_right {
  width: calc(100% - 50px);
  float: right;
  padding-left: 15px;
}

.users .card__title {
  width: 100%;
  float: left;
  margin-bottom: 0 !important;
  border-bottom: solid 1px #e8e8e8;
}

.users .card-body {
  padding: 15px 15px 5px 15px !important;
  border-radius: 0 !important;
}

.paypal-button-row {
  display: none !important;
}

.paypal-powered-by {
  display: none !important;
}

.users .card__bottom span {
  color: #a5a6ac;
  width: 45%;
  float: left;
  font-size: 11px;
}

.users .right_icons {
  width: 100px;
  float: right;
  text-align: right;
}

.users .right_icons img {
  width: 17px !important;
  margin-left: 10px;
  cursor: pointer;
}

.users .card__bottom {
  width: calc(100% - 65px);
  float: right;
  padding-top: 5px;
}

.users .card {
  width: 48% !important;
  margin: 1% !important;
  float: left;
  height: auto !important;
  padding-bottom: 0 !important;
}

.sidebar__submenu .sidebar__link {
  padding-left: 23px;
  line-height: 10px;
  border-bottom: solid 1px #39435e !important;
}

.sidebar__link-active .sidebar__link-icon {
  color: #fff !important;
}

.table thead th {
  border-bottom: 2px solid #fff !important;
  border-top: 1px solid #fff !important;
  padding: 6px 0.75rem;
  background: #f5f6fa !important;
  color: #a3a6b4 !important;
}

.table td {
  font-size: 11px !important;
  color: #8b8c95;
  text-align: center;
}

.card__title {
  margin-bottom: 0px;
  width: 100%;
  float: left;
}

.card_title {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}

.border-top-custom {
  border-top: 2px solid #3f4762;
}

.showmore {
  width: 100%;
  float: left;
  text-align: center;
  color: #15a2f7;
  cursor: pointer;
}

.companies {
  padding: 0 !important;
}

.companies .card-body {
  padding: 20px;
}

.sidebar__link:before {
  width: 0px !important;
  background: #a3a0fb !important;
}

.sidebar__link-active {
  // background: rgba(255,255,255,0.3);
  text-decoration: underline;
  color: #fff;
}

.dashboard_top .card {
  width: 33.33%;
  float: left;
  padding: 15px;
  padding-top: 0 !important;
  border: none !important;
}

.dashboard_top .card-body {
  padding: 12px;
  width: 100%;
  float: left;
  background-color: #6c6f7f;
}

.dashboard_top .card__title_top {
  font-size: 10px;
  line-height: 0px;
  color: #fff;

}

.dashboard_top .card_body_left {
  width: 50%;
  float: left;
}

.dashboard_top .card_body_right {
  width: 50%;
  float: left;
}

.dashboard_top .card_body_left span {
  width: 100%;
  float: left;
  color: #fff;
}

.dashboard_top .card_body_left_top {
  font-size: 21px;
  font-weight: bold;
}

.dashboard_top .card_body_right img {
  width: 100px;
  float: right;
  margin-top: 20px;
}

.top_products {
  float: left;
}

.dashboard_middle {
  margin-bottom: 20px;
  float: left;
}

.dashboard_middle .card {
  background: #fff;
  border-bottom: solid 4px #e4e4e4;
}

.dashboard_middle .dropdown {
  float: right;
}

.dashboard_middle h2 {
  padding: 8px 0 0px 20px;
  font-size: 21px;
}

.referrer,
.mapdetails_top {
  margin-top: 20px;
}

.mapdetails_top .card__title {
  margin-bottom: 10px;
  float: left;
  width: 100%;
}


.mapdetails_top .card__title span {
  width: 50%;
  text-align: right;
  float: left;
}

.mapdetails_top .card__title .card__title_top {
  text-align: left !important;
  font-weight: 700;
  font-size: 12px;
}

.mapdetails_top .card__title_right {
  color: #15a2f7;
  font-size: 12px;
  text-transform: lowercase;
  cursor: pointer;
}

.mapdetails_top .country {
  width: 100%;
  float: left;
  padding: 4px 0;
}

.mapdetails_top .country span {
  width: 50%;
  float: left;
}

.mapdetails_top .country_left {
  text-align: left;
}

.mapdetails_top .country_right {
  text-align: right;
}

.compantlist {
  padding: 0;
}

.compantlist .table-hover tbody tr:hover {
  background-color: #fff !important;
}

.compantlist .table td img {
  width: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.compantlist .card__title {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.compantlist .card__title .bold-text {
  float: left;
  width: 100%;
}

.compantlist .card__title .bold-text span {
  line-height: 30px !important;
}

.compantlist .card__title .bold-text input {
  width: 48% !important;
  float: right;
}

.sidebar__submenu {
  background-color: transparent !important;
}


.quickdetails_top .card__title {
  margin-bottom: 10px;
  float: left;
  width: 100%;
}


.quickdetails_top .card__title span {
  width: 50%;
  text-align: right;
  float: left;
}

.quickdetails_top .card__title .card__title_top {
  text-align: left !important;
  font-weight: 700;
  font-size: 12px;
}

.quickdetails_top .card__title_right {
  color: #15a2f7;
  font-size: 12px;
  text-transform: lowercase;
  cursor: pointer;
}

.quickdetails_top .country {
  width: 100%;
  float: left;
  padding: 10px 0;
}


.quickdetails_top .country_left {
  text-align: left;
  width: 60%;
  float: left;
}

.quickdetails_top .country_left img {
  width: 30px;
}

.quickdetails_top .country_right {
  text-align: right;
  font-size: 11px;
  line-height: 28px;
  color: #ccc;
  width: 40%;
  float: left;
}

.systemroles_top .card-body {
  padding: 15px;
}

.systemroles_top .card__title_top {
  font-weight: bold;
}

.systemroles_top .card-body .addbtn {
  font-size: 10px;
  padding: 0 10px;
}

.systemroles_top .roles_inner {
  width: 100%;
  float: left;
  padding: 10px 0;
  border-top: solid 1px #f5f5f5;
}

.systemroles_top .roles_inner img {
  width: 33px;
  margin-right: 10px;
}

.rolestabs .card-body {
  padding: 15px;
}

.rolestabs .card__title_top {
  font-weight: bold;
}

.rolestabs .nav-tabs .nav-link {
  margin: 0;
  border: none;
  cursor: pointer;
}

.rolestabs .nav-tabs .nav-link.active,
.rolestabs .nav-tabs .nav-link:hover {
  border-color: #fff #fff #39435e;
  border-bottom: solid 2px #39435e;
}

.tab_head {
  padding: 20px 10px;
  float: left;
  width: 100%;
  font-weight: 500;
  font-size: 15px;
}

.tab-pane .sidebar__link:hover,
.tab-pane .sidebar__category-wrap--open,
.tab-pane .sidebar__submenu {
  background: transparent !important;
}

.tab-pane .sidebar__category-icon {
  left: 6px !important;
  right: auto !important;
  top: 11px;
}

.tab-pane .sidebar__link:before {
  width: 0 !important;
}

.tab-pane .sidebar__link-icon {
  margin-right: 4px;
  margin-left: 4px;
}

.tab-pane .form-check-input {
  float: left;
  left: 45px;
  top: 7px;
}

.tab-pane .sidebar__link,
.tab-pane .sidebar__link-title {
  width: 100%;
}

.tab-pane .sidebar__submenu div {
  padding-left: 30px;
}

.medical_profile {
  width: 100%;
  float: left;
  padding: 10px 15px;
  border-top: solid 1px #efefef;
  cursor: pointer;
}

.medical_profile_left {
  width: 30px;
  float: left;
}

.medical_profile_middle {
  width: calc(100% - 90px);
  float: left;
  padding-left: 10px;
}

.medical_profile_right {
  width: 60px;
  float: left;
  font-size: 10px;
  color: #9f9f9f;
}

.namecard .card-body {
  padding: 0px;
}

.namecard .card__title {
  padding: 15px;
  margin: 0;
}

.medical_profile_middletop {
  font-weight: bold;
  width: 100%;
  float: left;

}

.medical_profile_middlebottom {
  width: 100%;
  float: left;
  color: #9f9f9f;
  font-size: 10px;
}

.medicaldetailscard {
  padding-left: 0 !important;
}

.namecard {
  padding-right: 0 !important;
}

.card-body {
  border-radius: 0 !important;
}

.medicaldetailscard .card-body {
  padding: 15px;
}

.medicaldetailscard .medical_profile {
  padding: 10px 0 !important;
  border-top: none;
  border-bottom: solid 1px #efefef;
}

.medicaldetailscard .card__title {
  margin-bottom: 10px;
  float: left;
  width: 100%;
}

.medicaldetailscard .medical_profile_left {
  width: 50px;
  float: left;
}

.medicaldetailscard .medical_profile_middle {
  width: calc(100% - 250px);
  float: left;
  padding-left: 10px;
}

.medicaldetailscard .medical_profile_right {
  width: 200px;
  float: left;
  font-size: 10px;
  color: #9f9f9f;
}

.btn_div {
  width: 100%;
  float: left;
}

.medicaldetailscard .medical_profile_middletop {
  font-size: 18px !important;
}

.medicaldetailscard .addbtn {
  padding: 1px 15px !important;
  background: #39435e !important;
  color: #fff;
  width: 40%;
  margin-top: 5px;
  margin-left: 10px;
  text-transform: uppercase;
}

.medicaldetailscard .medical_profile_right img {
  width: 20px;
  float: right;
  margin: 0 5px 5px 5px;
  cursor: pointer;
}

.medicaldetailscard .medical_profile_details {
  width: 100%;
  float: left;
  border-bottom: solid 1px #efefef;
}

.medicaldetailscard .medical_profile_details .medical_profile_details_left {
  width: 170px;
  float: left;
  padding: 10px 0;
}

.medicaldetailscard .medical_profile_details .medical_profile_details_left span {
  width: 100%;
  float: left;
  text-transform: capitalize;
  font-size: 11px;
}

.medicaldetailscard .description {
  width: 100%;
  float: left;
  padding: 10px 0;
}

.medicaldetailscard .description_left {
  width: 70%;
  float: left;
}

.medicaldetailscard .description_right {
  width: 30%;
  float: left;
}

.medicaldetailscard .description_left span {
  font-size: 11px;
  text-transform: capitalize;
}

.medicaldetailscard .description p {
  font-weight: bold;
  text-transform: capitalize;
}

.medicaldetailscard .description_right {
  font-size: 11px;
  text-transform: capitalize;
  font-weight: bold;
  margin-top: 22px;
}

.medicaldetailscard .description_right span {
  width: 100%;
  float: left;
}

.medicaldetailscard td img {
  width: 20px;
  margin-left: 10px;
  cursor: pointer;
  float: right;
}

.table-hover tbody tr:hover {
  background: #fff !important;
}

.mapdetails_top .card__title_total {
  width: 100% !important;
  float: left;
  text-align: left !important;
  font-weight: bold;
  margin-bottom: 10px;
}

.session_flow .card__title {
  border-bottom: solid 1px #e3e3e3;
  padding-bottom: 10px;
}

.session_flow .flowresult {
  width: 100%;
  float: left;
}

.session_flow .flowresult_left {
  width: 50%;
  float: left;
}

.session_flow .flowresult_right {
  width: 50%;
  float: right;
}

.session_flow .card_body {
  float: left;
}

.session_flow .flowresult span {
  width: 100%;
  float: left;
}

.session_flow .flowresult_leftamount,
.session_flow .flowresult_rightamount {
  color: #999;
  font-size: 10px;
}

.session_flow .showmore {
  margin-top: 15px;
}

.dashboard_right {
  margin-top: 20px;
}

.dashboard_right .card {
  width: 100%;
  height: 150px;
}


.dashboard_right .card__title_top {
  width: 100% !important;
  padding-top: 10px;
}

.dashboard_right .quickdetails {
  height: auto;
}

.database .card__title input {
  float: right;
  width: 50%;
}

.finance_top .card {
  width: 33% !important;
  float: left;
  padding: 0 10px;
  height: 56px !important;
}

.finance_top .card-body {
  padding: 10px !important;
}

.finance_top .card-body .card_body_left {
  width: 11%;
  float: left;
  margin-top: 3px;
}

.finance_top .card-body .card_body_left_last {
  width: 7%;
  float: left;
  margin-top: 9px;
}

.finance_top .card-body .card_body_left img,
.finance_top .card-body .card_body_left_last img {
  width: 100%;
}

.finance_top .card-body .card_body_right {
  float: left;
  width: 82%;
  padding-left: 10px;
}

.finance_top .card-body .card_body_right span {
  color: #56d9fe;
  width: 100%;
  float: left;
  font-weight: 500;
}

.finance_top .card-body .card_body_right .card_body_bottom {
  font-size: 10px;
  color: #656565;
}

.finance_top .card-body .card_body_second {
  color: #a3a1fb !important;
}

.finance_top .card-body .card_body_third {
  color: #4dda93 !important;
}

.dashboard_financechart {
  padding: 25px 25px 0 25px;
}

.dashboard_financechart .card {
  border-radius: 0;
}

.radarchart {
  margin: auto;
}

.userdashboard_top .card {
  background: #fff;
  border-radius: 0;
}

.userdashboard_top .card h2 {
  padding: 10px 15px 0 15px;
  font-size: 18px;
}

.userdashboard_top .dropdown {
  float: right;
  right: 0;
  top: 0;
}

.userdashboard_top button {
  margin-top: 10px;
  margin-bottom: 0px;
}

.skillschart {
  float: right;
  width: 130px;
  position: absolute;
  right: 15px;
}

.skillschart .dropdown,
.skillschart .dropdown button {
  width: 100%;
}

.card_head_right {
  font-weight: 400;
  text-transform: capitalize;
  float: right;
  text-align: right !important;
  font-size: 11px;
  color: #6ca4ff;
}

.topusercard .country {
  width: 100%;
  float: left;
}

.topusercard .country .country_left {
  width: 15%;
}

.topusercard .country .country_right {
  width: 85%;
}

.topusercard .country .country_right span {
  width: 100%;
  float: left;
  text-align: left;
  line-height: 15px;
  color: #ccc;
}

.topusercard .country .country_right .country_right_span {
  color: #797b84 !important;
}

.topusercard {
  margin-top: 15px;
}

.activeuser_left {
  width: 50%;
  float: left;
}

.activeuser_right {
  width: 50%;
  float: left;
}

.activeuser_right_inner,
.activeuser_right_inner_top {
  width: 100%;
  float: left;
}

.activeuser_right_inner_top span {
  color: #c3c3c3;
}

.progress_bar {
  width: 100%;
  float: left;
  height: 6px;
  background: #f1f1f1;
  color: #a3a1fb;
  border-radius: 10px;
}

.progress_bar_active {
  z-index: 99999;
  width: 63%;
  float: left;
  height: 6px;
  background: #a3a1fb;
  color: #a3a1fb;
  border-radius: 10px;
}

.activeuser_right_inner_right {
  float: right;
}

.activeuser_right_inner {
  margin: 10px 0;
}

.patientsdatabase {
  padding: 10px;
}

.patientsdatabase .card-body {
  padding: 15px !important;
}

.table thead th {
  padding: 6px 0.15rem !important;
  text-align: center;
  font-size: 10px;
}

.table td img {
  width: 20px !important;
  cursor: pointer;
}

.addform {
  width: 100%;
  float: left;
  background: #fff;
  padding: 25px;
}

.addform .form__form-group {
  width: 100%;
  float: left;
}

.addform .form__form-group-field {
  display: block;
}

.addform .form__form-group-field span {
  width: 100%;
  float: left;
}

.addform .form__form-group-field label {
  width: 30%;
  float: left;
  margin: 0;
  line-height: 16px;
  padding-top: 9px;
}

.addform .form__form-group-field input {
  width: 70%;
  float: left;
  border: solid 1px #ccc;
  padding: 11px 8px;
  border-radius: 5px;
}

.addform .form__form-group-field p {
  float: right;
  width: 70%;
  margin: 5px 0;
  font-size: 11px;
}

.uploaddiv {
  background: #f0f2f8;
  text-align: center;
  padding: 10px;
  width: 150px;
  float: left;
}

.uploaddiv img {
  height: 55px;
  width: auto !important;
}

.uploaddiv p {
  margin: 0 !important;
  width: 100% !important;
}

.uploaddiv button {
  background: #39435e;
  border: none;
  padding: 5px 9px;
  border-radius: 6px;
  color: #fff;
}

.buttonContainer {
  margin-right: 13px;
  margin-left: 13px;
  margin-bottom: 10px !important;
  margin-top: 10px;
}

.monthYearContainer {
  margin: 15px 5px 5px !important;
  display: flex;
}

.firstlastname .left,
.firstlastname .right {
  margin-bottom: 20px;
}

.formbuttons {
  width: 100%;
  float: right;
  text-align: right;
  padding: 20px;
}

.formbuttons button {
  background: #39435e;
  border: none;
  padding: 9px;
  margin-left: 10px;
  width: 130px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
}

.addcompanyform .uploaddiv button {
  display: block;
  margin: auto;
}

.cancelbtn {
  background: transparent !important;
  color: #9b9b9b !important;
  border: solid 1px #9b9b9b !important;
}

.nav {
  width: 100%;
}

.nav-item {
  cursor: pointer;
}

.nav-link {
  background: #f8f8f8;
}

.nav-tabs .nav-link.active {
  color: #39435e !important;
}

.topics {
  width: 100%;
  float: left;
}

.topics span {
  width: 100%;
  float: left;
  border-bottom: solid 1px #f7f7f7;
  padding: 8px;
  font-size: 11px;
  color: #39435e !important;
}

.floatright {
  float: right;
}

.casestep1 {
  width: 100%;
  float: left;
}

.createprogress {
  padding-left: 60px;
  text-align: center;
  display: inline-block;
}

.createprogressspan {
  width: 175px;
  float: left;
  text-align: center;
  height: 35px;
  border-bottom: solid 2px #39435e;
  position: relative;
  cursor: pointer;
}

.createprogressspan p {
  position: absolute;
  left: -40%;
  width: 100%;
  top: 46px;
}

.createprogressinner {
  width: 30px;
  height: 30px;
  border: solid 2px #39435e;
  margin: 20px auto;
  color: #39435e;
  border-radius: 30px;
  font-weight: bold;
  line-height: 25px;
  background: #f0f0f7;
  float: left;
}

.createprogressspan p span {
  width: 100%;
  float: left;
}

.bordernone {
  border: none !important;
}

.casestep1 {
  background: #fff;
  padding: 20px;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
}

.casestep1 h5 {
  text-align: left !important;
}

.addforms {
  margin-top: 50px;
  width: 100%;
}

.addforms h5 {
  padding: 15px 0 0 15px;
}

.leftmain {
  width: 48% !important;
  float: left;
}

.rightmain {
  width: 48% !important;
  float: right !important;
}

.leftmain .left {
  width: 100%;
  float: left;
}

.rightmain .left {
  width: 100%;
  float: right;
  margin-bottom: 40px;
}

textarea {
  width: 70%;
  border-radius: 5px;
}

.activeprogress {
  background: #39435e !important;
  color: #fff !important;
}

.nextbutton {
  background: #39435e;
  border: none;
  padding: 7px;
  margin-left: 10px;
  width: 130px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;

  text-align: center;
}

.cancelbutton {
  background: #fff !important;
  border: solid 1px #ccc !important;
  color: #a9a9a9 !important;
  padding: 7px;
  margin-left: 10px;
  width: 130px;
  border-radius: 3px;
  cursor: pointer;
  float: right;
  text-align: center;
}

.addforms h3 {
  padding: 0 20px;
  font-size: 15px;
  font-weight: bold;
}

.forms_description {
  padding: 0 20px 20px 20px;
  margin: 0;
}

.addforms_two .formbuttons {
  padding: 20px;
}

.generalinfo {
  width: 100%;
  padding: 0 50px;
}

.generalinfo .formbuttons {
  padding: 20px;
}

.newrole {
  width: 100%;
  float: left;
}

.newroles {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

.newroles_inner {
  position: absolute;
  padding: 15px;
  width: 370px;
  height: 280px;
  background: #fff;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  box-shadow: 1px 1px 5px #ccc;
}

.newroles_inner h3 {
  width: 100%;
  float: left;
  font-size: 16px;
}

.newroles_inner h3 span {
  float: right;
  cursor: pointer;
}

.newroles_inner p {
  width: 100%;
  float: left;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 10px;
}

.newroles_inner label {
  margin-bottom: 0;
}

.profile_top_div {
  width: 80%;
  float: left;
}

.profile_top_div img {
  width: 40px;
  float: left;
}

.profile_top_div span {
  float: left;
}

.profile_top_div span p,
.profile_top_div span span {
  margin: 0;
  float: left;
  padding-left: 10px;
  width: 100%;
}

.top_head_layout {
  padding: 0 !important;
  margin-bottom: 15px;
}

.top_head_layout_inner {
  padding: 15px;
  background: #fff;
  width: calc(100% - 30px) !important;
  margin: 15px;
}

.top_head_layout_inner p,
.top_head_layout_inner span {
  text-align: left !important;
}

input[type="file"] {
  padding: 0;
  width: 86px;
  margin: 0 24px;

}

.branding_left input[type="file"],
.branding_right input[type="file"] {
  overflow: hidden !important;
}

.branding_left_image {
  max-height: 140px !important;
}

.dot {
  display: none !important;
}

.optimisation {
  background: #3b455f !important;
  color: #fff !important;
}

.profileimage {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.profileimage img {
  width: 130px;
}

.rt-tbody {
  text-align: center;
}

.rt-tbody img {
  width: 70px;
  cursor: pointer;
}

.modal-footer .btn {
  margin-bottom: 0;
  padding: 5px 20px;
}

h5 input {
  float: right;
}

.ReactTable {
  width: 100%;
  margin-top: 10px;
  float: left;
}

.referrer .load {
  width: 100%;
  position: absolute;
}

.container-div {
  width: 100%;
}

.searchbtn {
  background: #616161;
  border-radius: 0 !important;
}

h5 input {
  padding: 10px;
  border-width: 0px;
  border-bottom: solid 2px #6d6c6c;
  height: 33px;
}


.generalinfo_desc {
  width: 50% !important;
  padding: 0 10px !important;
  float: left;
}

.css-15k3avv {
  width: 70% !important;
  float: right !important;
  right: 0 !important;
}

body {
  overflow-x: hidden;
}

.container-div {
  padding: 0 !important;
}

.container-div .card-body {
  padding: 15px !important;
}

.roles_inner_active,
.roles_inner :hover {
  background: #f0f0f7 !important;
}

.roles_inner {
  cursor: pointer !important;
}

.patient_info input,
.patient_info textarea {
  width: 70% !important;
  font-size: 13px;
}

.patient_info h5 {
  padding: 0;
  margin-bottom: 15px;
  font-weight: bold;
}

.patient_info1 h5 {
  font-weight: bold;
}

.examresults {
  color: #969696;
  font-weight: 500;
}

.addforms_div {
  padding: 25px 100px;
}

.addforms_div input {
  width: 50% !important;
}

.last_saffix {
  float: left;
  width: auto !important;
  padding: 8px 0 8px 8px;
}

.general__info__div {
  padding: 25px 40px !important;
}

.general__info .form__form-group-field {
  width: 100% !important;
}

.general__info .form__form-group-field label {
  width: 15%;
}

.examresults .examresults_title {
  width: 50% !important;
}

.general__info .last_saffix {
  width: 15% !important;
}

.form-check-inline,
.form-check-input {
  width: 100% !important;
  color: #fff;
}

.rt-td .form-check-input {
  width: auto !important;
}

.autocomplete {
  border: solid 2px #3d87ff !important;
  color: #3d87ff !important;
  border-radius: 5px;
  padding: 8px;
  background: #fff !important;
}

.addforms_two thead th {
  padding: 6px 8px !important;
  text-align: left;
}

.addforms_two td {
  text-align: left;
}

.addforms_two table {
  margin: 15px;
  width: calc(100% - 30px);
}

.actionss {
  width: 70px;
  float: right;
  padding: 10px 0 !important;
}

.actionss img {
  margin-left: 10px;
}

h5 .nextbutton {
  margin-right: 10px !important;
  float: right;
}

.Instructions {
  width: 48% !important;
  float: left;
}

.tabs__div .col {
  padding: 10px;
  border-bottom: solid 1px #ccc;
}

.progress__div {
  width: 300px;
  float: right;
  height: 20px;
}

.progress__span {
  background: #f0f2f8 !important;
  height: 10px;
  float: left;
  width: 88%;
  border-radius: 10px;
  margin-top: 5px;
}

.progress_active {
  background: #39435e;
  width: 30%;
  float: left;
  height: 10px;
  border-radius: 10px;
}

.progress__percentage {
  float: right;
  color: #39435e;
}

.card-header .btn:before {
  background-color: transparent !important;
}

.tab-content .card-body {
  padding: 0 !important;
}

.tab-content .card-body .card {
  padding-bottom: 0 !important;
}

.tab-content .card-header {
  background-color: transparent;
}

.qa_header {
  width: 25%;
  float: left;
}

.qa_header span {
  width: 100%;
  float: left;
  padding: 10px;
  color: #969696;
}

.qa_body {
  width: 60%;
  float: left;
  padding-top: 15px;
}

.qa_body span {
  width: 100%;
  float: left;
  font-weight: bold;
  border-bottom: solid 1px #ccc;
}

.qa_body span p {
  float: left;
  font-weight: bold;
  padding: 10px;
}

.qa_body span input {
  width: calc(100% - 80px);
  float: left;
  border: none;
  font-size: 13px;
  font-weight: bold;
  padding: 12px;
  outline: none;
}

.qa_body span img {
  float: right;
  width: 17px;
  margin: 12px;
  cursor: pointer;
}

.table td {
  border-top: none !important;
}

.table tr {
  border-bottom: solid 1px #f5f6fa;
}

.table td span {
  color: #4a79ff !important;
  cursor: pointer;
}

.modal_div {
  margin-bottom: 10px;
}

.modal_div .checkbox {
  float: left;

}

.modal_div .checkbox .form-check-input {
  margin-top: 13px;
}

.modal_div span {
  width: 65%;
  float: left;
  font-size: 14px;
  padding: 8px 0 8px 27px;
}

.modal_div select {
  width: 35%;
  font-size: 12px;
}

.modal-body {
  overflow: auto;
  max-height: 446px;
}

.general--table .table {
  height: 165px;
}

textarea {
  width: 70% !important;
}

.textarea {
  width: 100% !important;
}

.manitory {
  color: red;
  font-size: 20px;
  line-height: 0;
}

.role_trash {
  float: right;
  width: 21px !important;
  margin: 5px;
  cursor: pointer;
}

.topbar__menu {
  padding: 0px 0 !important;
  width: 100%;
  float: left;
}

.topbar__menu a {
  float: left;
  width: 100%;
  padding: 10px;
}

.google_auth {
  margin: 10px auto;
  text-align: center;
  display: inherit;
  padding: 10px 15px;
  background: #39435e;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
}

.formsuser .form__form-group-field {
  float: left;
  margin: 6px auto;
}

.formsuser .right {
  float: right !important;
}

.theme-light h3 {
  color: #616161 !important;
}

.rt-td p {
  color: #616161 !important;
}

.sidebar__link-title {
  font-family: 'Raleway', sans-serif !important;
  font-size: 12px !important;
  color: #fff !important;
}

.sidebar__link-active .sidebar__link-title {
  color: #fff !important;
}

.sidebar__link {
  // height: 50px;
  font-size: 20px !important;
  font-weight: bold;
  // border-bottom:solid 1px #39435e!important;
}

.sidebar__link-icon {
  color: #fff !important;
}

.sidebar__link:hover p {
  color: #fff !important;
}

.sidebar__link:hover .sidebar__link-icon {
  color: #fff !important;
}

.sidebar__category-icon {
  color: #fff;
  font-weight: bold;
  line-height: 7px;
}

.sidebar__category-wrap--open .sidebar__link-icon {
  color: #39435e !important;
}

.chart-wrapper {
  width: 100%;
  text-align: center;
  display: inline-block;
}

.chart-wrapper canvas {
  margin: 0 auto;
  // width: 80%!important;
  // height: 200px !important;
}

.from_to {
  width: 70%;
  float: right;
  margin: 10px 0;
}

.from_to1 {
  float: left;
  margin: 0;
  z-index: 9999;
  width: 100%;

}

.from_date label {
  margin-right: 10px !important;
}

.timeContainer {
  display: none !important;
}

.react-date-picker__clear-button {
  display: none !important;
}

.from_to2 {
  width: 70%;
  float: right;
  padding-right: 20px;
  // margin-top: -26px;
}

.from_to2 .form-control {
  font-size: 12px !important;
}

.form__form-group-field {
  width: 50%;
  display: flex;
  margin: auto;
  float: left;
  padding: 20px 15px;
}

.form__form-group-field span {
  width: 100%;
}

.form__form-group-field span label {
  margin-right: 20px;
  width: 100%;
  font-weight: bold;
}

.form__form-group-field span input,
.form__form-group-field span select {
  padding: 10px;
  width: 100%;
  float: left;
  border-radius: 5px;
  border: solid 1px #ccc;
  // height:40px;
}

// .onboard .form__form-group-field span input,.onboard .form__form-group-field span select
// {
//   height:auto!important;
// }

.form_footer {
  width: 100%;
  float: left;
  padding: 30px 15px;
}

.form_footer button {
  font-size: 14px;
  border: none;
  border-radius: 5px;
  margin-left: 20px;
  color: #ffff;
  float: right;
}

.cancelbtn {
  background: #999 !important;
  color: #fff !important;
}

.download_buttons {
  width: 80% !important;
  float: right;
  margin: 10px 0;
}

.download_buttons .from_date {
  float: right !important;
}

.from_date label {
  margin: 0;
  line-height: 25px;
}

.days_selectbox {
  width: 200px;
  float: right;
  margin-right: 20px;
}

.days_selectbox1 {
  width: 15%;
  float: right;
  margin-right: 20px;
  margin-top: 10px;
}

.days_selectbox2 {
  width: 27%;
  float: right;
  margin-right: 20px;
  margin-top: 25px;
}

.days_selectbox2 .form-control {
  font-size: 12px;
}

.pie_chart {
  width: 100%;
  float: left;
  height: 400px !important;
}

.chart_head,
.dashboard_middle h2 {
  float: left;
  font-size: 14px;
  font-weight: bold;
  color: #6c6c6c;
}

.totals {
  width: 100%;
  padding: 20px;
}

.totals span {
  width: 100%;
  float: right;
  text-align: right;
  font-size: 18px;
}


.sidebar__category-wrap--open .sidebar__link-icon,
.sidebar__category-wrap--open .sidebar__link-icon:hover,
.sidebar__category-wrap--open :hover {
  color: #fff !important;
}

.sidebar__category-wrap--open .sidebar__category:hover,
.sidebar__category-wrap--open .sidebar__category-icon {
  color: #fff !important;
}

.sidebar__category-wrap--open .sidebar__link:hover {
  color: #fff !important;
}

// .sidebar__submenu-wrap{
//   border-top: solid 1px #39435e!important;
// }

.sidebar__category-wrap--open .sidebar__link:hover .sidebar__link-icon {
  color: #fff !important;
}

.search1 {
  width: 25%;
  float: right;
}

.topbar__button {
  // padding: 30px;
  // height: 115px;
  // border-right: solid 1px #ccc;
}

// .sidebar__wrapper
// {
//   padding: 40px 0;
// }

.systemroles_top .card,
.rolestabs .card,
.card {
  border: solid 1px #e4e4e4;
  border-radius: 0;
}

#returns__overview .ReactTable.-striped .rt-tr.-odd {
  background: transparent !important;
}

#orders__table .ReactTable.-striped .rt-tr.-odd {
  background: transparent !important;
}

#top_products__table .ReactTable.-striped .rt-tr.-odd {
  background: transparent !important;
}

#transaction__history .ReactTable.-striped .rt-tr.-odd {
  background: transparent !important;
}

#top__merchants_table .ReactTable.-striped .rt-tr.-odd {
  background: transparent !important;
}

#user_managemant__table .ReactTable.-striped .rt-tr.-odd {
  background: transparent !important;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px #f1f1f1 !important;
}

.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border-right: 2px solid #d9d9d9 !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
}

.ReactTable {
  border: none !important;
}

.card__title h5 {
  font-size: 13px;
  width: 100%;
  float: left;
  border-bottom: solid 2px #d9d9d9;
  padding: 0 0 10px 0;
  margin-bottom: 15px;
  color: #6a6a6a !important;
}

.search_filters {
  float: left !important;
  width: 88%;
}

.search_filter_btn {
  float: left !important;
  width: 120px !important;
  max-width: none;
  padding: 0 2px;
}

.search_filters .btn {
  float: left !important;
  width: 88%;
}

.search_filters .btn1 {
  float: left !important;
  width: 100%;
  padding: 4px 0px;
  margin: 0;
}


.inventory_status .download_buttons {
  display: none;
}

.inventory_table {
  float: left;
  width: 100%;
  height: 200px;
  overflow: auto;
}

.inventory_status .card,
.pie_graph .card {
  height: 310px !important;
  border-bottom: solid 4px #e4e4e4;
}

.sales_head .search_filters {
  float: right !important;
}

.right_side_sales {
  width: 25%;
  float: right;
  position: absolute;
  right: 20px;
  top: 80px;
  text-align: center;

}

.right_side_sales span {
  padding: 50px 0;
  float: left;
  width: 100%;

}

.right_side_sales h3 {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin: 8px 0px;
}

.right_side_sales button {
  padding: 8px 15px;
  font-size: 20px;
  margin-bottom: 20px;
}

.signup_link {
  margin-left: 20px;
  margin-right: 20px;
}

.search .form-control {
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #eaeaea 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%, #eaeaea 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%, #eaeaea 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eaeaea', GradientType=0);
  /* IE6-9 */
  height: 30px;
}

.search button {
  background: #ffffff;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #eaeaea 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%, #eaeaea 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%, #eaeaea 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eaeaea', GradientType=0);
  /* IE6-9 */
  height: 30px;
}


.btn:focus {
  color: #fff !important;
}

.recharts-wrapper {
  width: 100% !important;
}

// .chart-wrapper canvas {
//   margin: 0 auto!important;
//   height: 200px!important;
//   width: 420px!important;
// }

// .recharts-wrapper svg:not(:root){
//   width: auto!important;
// }

.responsive_graph {
  width: 75%;
  border-right: solid 2px #ccc;
}

.css-10nd86i,
.css-vj8t7z,
.css-1hwfws3 {
  // height:40px;
}

.css-xp4uvy {
  padding-top: 10px !important;
}

.react-calendar__month-view__weekdays__weekday,
.react-calendar__tile {
  font-size: 12px !important;
}

.react-calendar__navigation__label {
  font-size: 16px !important;
}

.react-calendar__navigation {
  margin-bottom: 0 !important;
}

.react-date-picker__inputGroup {
  font-size: 16px !important;
  text-align: center;
}

.reports_head {
  background: #f1f1f1;
  padding: 7px !important;
  border-radius: 5px;
  border: solid 1px #ccc;
  color: #000 !important;
  font-weight: bold;
}

.reports_left {
  width: 49%;
  float: left;
  border: solid 2px #ccc;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.reports_right {
  width: 49%;
  float: right;
  border: solid 2px #ccc;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.reports_left_inner {
  width: 50px;
  float: left;
}

.reports_right_inner {
  width: calc(100% - 50px);
  float: right;
  padding-left: 15px;
}

.reports_right_inner span {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: bold;
}

.reports_right_inner p {
  margin: 0;
  font-size: 11px;
  text-transform: capitalize;
  color: #000;
}

// .css-10nd86i,.css-vj8t7z,.css-1hwfws3,.css-1wy0on6
// {
//   height:40px!important;
// }
.load {
  position: relative;
}

.load__icon {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.container__wrap .load__icon {
  left: 0px !important;
}

.right input[type="file"] {
  margin: 0 !important;
  border: none !important;
  border-radius: 0 !important;
}

.search .btn {
  padding: 0 !important;
}

.from_date {
  width: 185px !important;
  padding-left: 0;
  text-align: right;
}

.react-date-picker__inputGroup {
  padding: 0 0 0 6px !important;
  font-weight: 100;
  font-size: 15px !important;
}

.react-date-picker__wrapper {
  border-radius: 3px !important;
}

.add_products {
  float: right;
}

.bold-text,
.step1,
.sidebar__block {
  position: relative
}

// .alignCenter{
//   position: relative;
//   top:60%;
//   left:52%;
//   transform: translate(-50%, -55%);
//   margin: auto;
//   text-align: center
// }


.alignCenter+.overlayIntro {
  position: fixed;
  top: -75px;
  left: 210px;
}

// .overlayIntro + .react-user-tour-container {
//   position: fixed !important;
//   left: 45% !important;
//   transform: translate(-50%, -20%) !important;
// }


.alignCenter+.overlayIntro {
  .react-user-tour-arrow {
    display: none
  }
}




.alignTop+.overlayIntro {
  .react-user-tour-arrow {
    display: none
  }
}

.alignTop+.overlayIntro {
  position: absolute;
  top: 190px;
  left: 175px;
}

.overlayDash {
  position: relative;
  left: 240px;

  .overlayIntro {
    position: relative;
    z-index: 9999;
  }
}

.overlayIntro {
  // position: relative;
  z-index: 9999;


}

.overlayIntro.resizePop {
  .react-user-tour-container>div:first-child {
    min-height: 210px !important;
  }
}

.overlayIntro.resizeInventoryPop {
  .react-user-tour-container>div:first-child {
    height: auto !important;
    min-height: 150px !important;
    padding: 13px 10px 50px 20px !important;
  }
}

.overlayIntro.resizeSalesPop {
  .react-user-tour-container>div:first-child {
    height: auto !important;
    min-height: 150px !important;
    padding: 13px 10px 45px 20px !important;
  }
}

// .react-user-tour-close {
//   display:none !important;
// }
.react-user-tour-back-button {
  display: none !important;
}

.overlayHelp {
  position: absolute;
  top: 100px;
  right: 0;
  bottom: 0;
  left: -60px
}

.react-user-tour-container>div:first-child>div:first-child {
  display: none !important
}

.overlayHelp {
  .react-user-tour-container>div:first-child>div:first-child {
    top: -12px !important;
    left: 30% !important;
  }

  .react-user-tour-arrow {
    left: 20% !important;
  }
}

// .sidebar__scroll{overflow: visible!important}

.identifiers {
  width: calc(100% - 30px);
  float: left;
  margin: 15px;
  // background: #fbfafa;
  border-radius: 5px;
  border: solid 1px #ccc;
}

.form_header {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
}

.form_header span {
  font-size: 12px !important;
  padding-left: 5px;
}

.middle-width {
  width: 25%;
}

.image-width1 {
  width: 30%;
}

.image-width2 {
  width: 30%;
  float: left;
}

.image-width2 .form__form-group-field {
  width: 100% !important;
  display: grid !important;
}

.image-width_or {
  text-align: center;
  padding: 10px 0;
}

.image-width {
  width: 33.33%;
}

.image-width .css-1pcexqc-container {
  z-index: 99 !important;
}

.image-width-market .css-1pcexqc-container {
  z-index: 199 !important;
}

.image-upload_file input {
  margin: 0 !important;
}

.upload_img {
  float: left;
  height: 85px;
}

.upload_img_block img {
  background-color: #ccc;
  margin-top: 15px;
  cursor: pointer;
}

.upload_img_block {
  position: relative;
  width: 85px !important;
  display: flex;
  // margin: 0 auto;
  float: left;
}

.image_upload_title {
  width: 100% !important;
}

.image_upload_split {
  float: right;
  width: calc(100% - 98px) !important;
  margin-top: 23px;
}

.upload_img_close {
  font-size: 20px;
  position: absolute;
  top: -4px;
  right: -7px;
  cursor: pointer;
  color: red;
  font-weight: bold;
  width: 15px;
}

.image-width-delete {
  width: 9%;
  background: red;
  border: none;
  color: #fff;
  padding: 10px 0;
  font-size: 16px;
  margin-top: 40px;
  border-radius: 5px;
  margin-right: 1%;
  float: right;
}

.images_div {
  width: 100%;
  float: left;
  border-bottom: solid 1px #e8e8e8;
  margin-top: 0px;
  padding-bottom: 23px;
}

.image_div_center {
  padding: 0 20%;
}

.image_div_center .image-width1 {
  width: 60% !important;
}

.image_div_center .image-order {
  width: 22% !important;
}

.image-width-delete {
  width: 17% !important;
}

.add-more {
  width: 100%;
  float: left;
  padding-right: 15px;
  margin-top: 20px;
  position: relative;
}

.addbtn_guide {
  margin: auto;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 7px 15px;
}

.autosync {
  padding: 15px;
  width: 33.33%;
  float: left;
}

.autosync div {
  float: left;
  padding: 15px;
  background: #fbfafa;
  border-radius: 5px;
  border: solid 1px #f1f1f1;
}

.autosync label {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}

.autosync span {
  float: left;
  width: 135px;
}

.autosync span input {
  width: 22px;
  height: 22px;
  float: left;
}


.autosync span p {
  float: left;
  margin: 2px 5px;
  font-size: 13px;
  font-weight: bold;
}

.pencil_edit {
  width: 22px !important;
}

.search_orders1 {
  width: 50% !important;
}

.search_orders1 span {
  width: 42% !important;
  float: right;
  margin: 2% 0% 2% 2%;;
}

.btn_sub {
  padding: 8px 15px;
  float: left;
  font-size: 18px;
}


.channel_div {
  float: right;
}

.active_ebay {
  background: #6f6f6f !important;
  color: #fff !important;
}

.active_walmart {
  background: #3b455f !important;
  color: #fff !important;
}

.active_amazon {
  background: #8e919f !important;
  color: #fff !important;
}

// .btn{
//   color:#212528!important;
// }
// .btn:active{
//   color:#212528!important;
// }
.Walmart {
  background: #3b455f !important;
  color: #fff !important;
}

.Sales {
  background: #000 !important;
  color: #fff !important;
}

.Walmart:before {
  background-color: transparent !important;
}

.btn:before {
  background-color: transparent !important;
}



.btn:focus {
  color: #fff !important;
  display: none;
}

.ebay {
  background: #6f6f6f !important;
  color: #fff !important;
}

.ebay:before {
  background-color: transparent !important;
}

.Shopify {
  background: #17a2b8 !important;
  color: #fff !important;
}

.Shopify:before {
  background-color: transparent !important;
}

.ValetShop {
  background: #28a745 !important;
  color: #fff !important;
}

.ValetShop:before {
  background-color: transparent !important;
}

.Amazon {
  background: #8e919f !important;
  color: #fff !important;
}

.Amazon:before {
  background-color: transparent !important;
}

.AmazonCanada {
  background: #ad7b9d !important;
  color: #fff !important;
}

.AmazonCanada:before {
  background-color: transparent !important;
}

.WalmartCanada {
  background: #755050 !important;
  color: #fff !important;
}

.WalmartCanada:before {
  background-color: transparent !important;
}

.Bonanza {
  background: #61719a !important;
  color: #fff !important;
}

.Bonanza:before {
  background-color: transparent !important;
}

.Sears {
  background: #c77e7e !important;
  color: #fff !important;
}

.Sears:before {
  background-color: transparent !important;
}

.Facebook {
  background: #1877f2 !important;
  color: #fff !important;
}

.Facebook:before {
  background-color: transparent !important;
}

.Google {
  background: #fbbc05 !important;
  color: #fff !important;
}

.Google:before {
  background-color: transparent !important;
}

.Wish {
  background: #9caad6 !important;
  color: #fff !important;
}

.Wish:before {
  background-color: transparent !important;
}

// .search_filter_btn .Sales
// {
//   width: 70px!important;
// }

// .search_filter_btn .AmazonCanada
// {
//   width: 130px!important;
// }
// .search_filter_btn .WalmartCanada
// {
//   width: 130px!important;
// }
// .search_filter_btn .Bonanza
// {
//   width: 90px!important;
// }
// .search_filter_btn .Wish
// {
//   width: 60px!important;
// }
// .search_filter_btn .Sears
// {
//   width: 70px!important;
// }
// .search_filter_btn .Walmart
// {
//   width: 90px!important;
// }
// .search_filter_btn .ebay
// {
//   width: 65px!important;
// }
// .search_filter_btn .Amazon
// {
//   width: 90px!important;
// }


.load__icon-wrap {
  font-weight: bold;
  color: #6c6f7f;
  font-size: 16px;
  z-index: 9999;
}

.load {
  width: 100% !important;
  position: fixed;
  height: 100% !important;
  text-align: center;
  right: 0;
}

.recharts-surface {
  width: 100% !important;
}

.recharts-default-tooltip {
  float: left;
}

.left_billing {
  width: 50%;
  float: left;
  border-right: solid 1px #ccc;
  text-align: left;
  padding: 0 25px 0 20px;
}

.left_billing h2 {
  text-align: left;
  font-weight: bold;
  padding: 15px 0;
}

.left_billing h3 {
  font-size: 18px;
  font-weight: bold;
}

.left_billing p {
  margin-top: 0 !important;
  margin-bottom: 25px !important;
}

.billing_form label {
  float: left;
}

.billing_form_half {
  float: left;
  width: 50%;
  padding: 0 10px;
}

.expiration_date {
  text-align: left;
}

.forms_billing {
  float: left;
}

.billing_form label {
  float: left;
  margin-bottom: 0;
}

.billing_form .form__form-group {
  margin-bottom: 12px !important;
}

.paypal_btn {
  background: #f3c30f !important;
  width: 100%;
  border-radius: 30px;
  border: none;
}

.paypal_btn img {
  width: 107px;
  padding: 8px 0;
}

.billing_form_half_1 {
  float: left;
  width: 40% !important;
  padding: 0 10px;
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 0px;
  margin-right: 5%;
}

.billing_form_half_1 input {
  border: none !important;
}

.billing_form_div {
  float: left;
  width: 100%;
  padding: 0 10px;
}

.account__head {
  margin-bottom: 15px;
  padding-left: 10px;
  text-align: center;
  margin-top: 42px;
}

.account__card_billing {
  width: 900px;
}

.account_head_billing {
  width: 50%;
  float: right;
}

.spliter {
  border: solid 1px #f1f1f1 !important;
  width: 100%;
  float: left;
}

.expiration_date {
  float: left !important;
  width: 100% !important;
}

.expiration_date_profile {
  float: left !important;
  width: 100% !important;
  padding-right: 200px !important;
}


.ques_btn {
  padding: 0;
  margin: 3px 10px !important;
}

.check_box {
  float: left;
  width: 16px !important;
}

.check_box_span {
  float: left;
  padding: 6px 0 0 20px;
}

.check_box_span a {
  color: #4a6abf;
  font-weight: bold;
  cursor: pointer;
}


.agreement span {
  float: left;
  margin: 10px 0px;
  width: 100%;
}

.agreement .react-datepicker-wrapper {
  width: 100%;
}

.closemodal {
  float: right;
  cursor: pointer;
  color: #FF5722 !important;
}

.card_details {
  margin-bottom: 10px !important;
  padding: 0 10px;
}

.CardDemo {
  width: 100%;
  padding: 0 10px;

}

.CardDemo label {
  display: block !important;
  width: 100%;
  text-align: left;

}

.StripeElement {
  border: solid 1px #ccc;
  padding: 8px;
  border-radius: 5px;
}

.error {
  color: red;
  text-align: center;
  width: 100%;
}

.full_width {
  width: 100%;
  float: left;
  padding: 0 36%;
}

.addcard_green {
  background: green !important;
}

.deletecard_red {
  background: #c73a0d !important;
}

.suffix {
  position: absolute;
  right: 10px;
  width: auto !important;
  top: 29px;
}

.prefix {
  position: absolute;
  left: 10px;
  width: auto !important;
  top: 29px;
}

.prefixtext {
  position: relative;
}

.prefixinput {
  padding-left: 54px !important;
  padding-right: 110px !important;
}

.connected {
  display: inline !important;
  font-weight: bold !important;
  margin-left: 10px !important;
  color: #009688 !important;
}

.not_connected {
  display: inline !important;
  font-weight: bold !important;
  margin-left: 10px !important;
  color: #c7441c !important;
}

.update-btn {
  background: #009688 !important;
  color: #fff !important;
}

.disconnect-btn {
  background: #c7441c !important;
  color: #fff !important;
}

.question_name {
  padding: 3px 7px;
  background: #f1f1f1;
  text-align: center;
}

.question_name img {
  margin: 0
}

.image-width-1 {
  width: 100%;
}

.image-width-2 {
  width: 100%;
}

.wrapperClassName {
  border: solid 1px #ccc;
  border-radius: 2px;

}

.wrapperClassName img {
  width: 13px !important;
}

// .public-DraftStyleDefault-ltr
// {
//   margin: 16px!important;
// }

.DraftEditor-root {
  padding: 0 10px 10px 10px;
}

.public-DraftStyleDefault-block {
  margin: 0.5em 0;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 10px 0;
  padding: 0;
}

.cropImage img {
  width: 255px;
  height: 255px;
}

.css-1dkwqii {
  width: 337px;
  height: 330px;
  position: relative !important;
  background: rgba(0, 0, 0, 0.3);
}

// .css-1dkwqii .css-bdjl4r
// {
//   width: 255px!important;
//   height: 255px!important;
// }

.css-ebdd77 {
  width: auto;
  height: 255px;
}

.cropsave {
  margin: 0;
  font-size: 12px;
}

.cropclose {
  background: #F44336 !important;

  margin: 0;
  margin-left: 15px !important;
  font-size: 12px;
}

.cropbtn {
  width: 100%;
  margin-bottom: 0;
  margin-top: 16px;
}

.editimage {
  text-align: left;
  padding-bottom: 13px;
  font-size: 15px;
  font-weight: bold;
}

.sidebar {
  margin-top: 0 !important;
  height: 80px !important;
  position: absolute;
  z-index: 99999;
  left: 240px;
  position: fixed;
  width: calc(100% - 370px) !important;
}

.topbar {
  z-index: 99990;
}

.sidebar__link-icon {
  display: none;
}

.sidebar__link {
  width: auto !important;
  padding: 31px 10px !important;
  height: 80px;
}

.sidebar__link p {
  position: relative;
  left: 0;
  width: auto !important;
  font-size: 14px !important;
}

.sidebar__block {
  position: relative !important;
  float: right;
  @media screen and (min-width: 993px) {
    display: flex;
    justify-content: flex-end;
  }

}

.container__wrap {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 80px;
}

.sidebar__scroll {
  width: 100% !important;
}

.sidebar__block {
  height: 80px;
}

.sidebar__block a {
  float: left;
}

.sidebar__block {
  position: absolute;
  z-index: 1002;
}

.sidebar {
  background: #3d445a !important;
  box-shadow: none !important;
  border: none !important;
}

.topbar__wrapper {
  height: 80px !important;
  background: #3d445a;
}

.topbar__profile {
  margin-right: 0 !important;
  border: none;
}

// .sidebar__link
// {
//   width: auto!important;
// }

// .css-ebdd77
// {
//   max-width: 75%!important;
//   max-height: 75%!important;
// }

// .css-1492t68
// {
//   margin-top: 6px!important;
// }
.topbar__avatar-img {
  width: 50px !important;
  height: 50px !important;
  border: solid 2px #fff;
}

.theme-light .topbar__avatar:hover,
.theme-light .topbar__avatar:focus,
.theme-light .topbar__avatar:active,
.theme-light .topbar__avatar:focus:active {
  background-color: #3c4358;
}


.profile_select {
  width: 84%;
  margin-top: 16px;
}

.profile_select .form-group {
  float: left !important;
  width: 33.33% !important;
}

.profile_image {
  width: 16%;
}

.Toastify__toast-container--top-right {
  top: 2.3em !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}


.tooltipMark {
  position: relative;
  display: inline-block;
}

.tooltipMark .tooltipText {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 110%;
  background-color: #3d4459;
}

.tooltipMark .tooltipText::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

.tooltipMark:hover .tooltipText {
  visibility: visible;
}

.sampleCsv button {
  background: none;
  border: none;
  color: #fff;
}

.onboard .next {
  width: 100%;
  background: #3b455f !important;
  color: #fff !important;
  padding: 8px 0;
  font-size: 16px;
  margin-bottom: 10 !important;
  margin-top: 6px !important;
}

.onboard .form-group {
  float: left !important;
  width: 100%;
  // margin-top: 10px;
}

.image_uploadhead {
  padding-top: 33px !important;
}


.ReactTable .-loading.-active {
  z-index: 0 !important;
}

.close_right {
  width: 20px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.mantory {
  color: red;
  padding-left: 3px;
}

.profilecards .download_buttons {
  display: none;
}

.product-title {
  width: 100%;
  float: left;
}

.product_options {
  float: left;
  width: 100px;
  margin-top: 0px;
}

.product_options input {
  width: 20px !important;
  height: 18px;
  float: left;
  margin: 4px 4px 4px 0;
}

.product_options span {
  width: calc(100% - 20px);
  font-size: 16px;
}

.product_add_button {
  float: left;
  margin-top: 0px;
}

.product_add_button button {
  background: #3d445a !important;
  color: #fff !important;
  margin-bottom: 8px;
}

.color_box {
  width: 100%;
  float: left;
}

.color_first {
  width: 50%;
  float: left;
  font-size: 20px;
}

.product_options_div {
  width: 50%;
  float: left;
  padding: 12px;
}

.product_options_div2 {
  margin-top: 87px !important;
  padding: 0 !important;
}

.product_options_div2 .image-width {
  width: 75% !important;
  float: right;
}

.product-title label {
  font-weight: bold;
}


.addcards {
  width: 100%;
  float: left;
}

.billing_card {
  width: calc(33.33% - 20px);
  float: left;
  background-color: #3d445a;
  border-radius: 20px;
  padding: 10px 20px 20px 20px;
  margin: 10px;
}

.billing_card .billing_card_block {
  width: 100%;
  float: left;
  padding: 5px;
}

.billing_card .billing_card_left {
  width: 40%;
  float: left;
  color: #fff;
  font-weight: bold;
}

.billing_card .billing_card_right {
  width: 60%;
  float: left;
  color: #fff;
}

.primary_card {
  width: 100%;
  float: left;
  text-align: left;
  color: #9a9a9a;
  font-weight: bold;
  font-size: 16px;
}

.edit_card img {
  width: 20px;
}

.edit_card {
  border-radius: 10px;
  border: none;
  height: 31px;
  float: right;
  margin-left: 15px;
}

.expo_date {
  width: 100% !important;
  float: left !important;
  padding-right: 0px !important;
  font-weight: bold;
}

.color_first input {
  padding: 7px;
  border-radius: 10px;
  border: solid 1px #e8e8e8;
}

.color_second a {
  float: right;
  color: #738cdc;
  font-size: 14px;
  padding: 3px;
}

.add_color {
  float: left;
  width: 100%;
  background: #fff;
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 0 10px;
  margin-top: 5px;
}

.add_color input {
  padding: 6px;
  border-radius: 10px;
  border: solid 1px #ccc;
  width: 115px;
  margin: 10px 15px 10px 0;
  float: left;
  background: #fbfafa;
}

.add_color a {
  background: #3d445a;
  color: #fff;
  width: 24px;
  height: 24px;
  float: left;
  text-align: center;
  font-size: 22px;
  border-radius: 24px;
  line-height: 26px;
  margin: 12px 0;
}

.add_color img {
  margin-top: -5px;
}

.add_color span {
  position: relative;
  float: left;
}

.add_color span a {
  position: absolute;
  right: 12px;
  top: 2px;
  background: transparent;
  color: #3d445a;
  font-size: 17px;
  font-weight: bold;
  margin: 0;
  width: 10px;
}

.options_block {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.edit_photo {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 16px !important;
  height: 16px !important;
  background: #e4e4e4;
  border-radius: 15px;
  cursor: pointer;
  padding: 2px;
}

.swatch_box {
  position: absolute;
  margin-top: 12px;
  margin-left: 5px;
  padding: 0;
  box-shadow: none !important;
  width: 20px;
}

.swatch_box div {
  padding: 0;
  box-shadow: none !important;
  width: 14px !important;
}

.colorinput input {
  padding-left: 31px;
}

.addedvarient {
  width: calc(100% - 30px);
  float: left;
  background: #ffffff;
  border: solid 1px #cccccc;
  margin: 15px;
  padding: 15px;
  position: relative;
  /* border-radius: 5px; */
  border-left: solid 3px #3d445a;
}

.identifiers_tabs .react-tabs__tab-list {
  float: left;
  border-bottom: none !important;
}

.addedvarient .image {
  width: 5%;
  float: left;
}

.addedvarient .image span {
  position: relative;
  float: left;
  border: solid 1px #ccc;
}

.addedvarient .variation {
  width: 10%;
  float: left;
}


.addedvarient .image label,
.addedvarient .variation label {
  width: 100%;
  float: left;
  font-size: 16px;
  font-weight: bold;
}

.form__form-group label {
  font-size: 16px;
  font-weight: bold;
}

.addedvarient .image img {
  width: 40px;
  height: 40px;
}

.addedvarient .form__form-group {
  margin: auto;
  float: left;
  padding: 0px 15px;
  width: 10.6%;
}

.addedvarient input {
  padding: 10px;
  width: 100%;
  float: left;
  border-radius: 5px;
  border: solid 1px #ccc;
}

.addedvarient .closes {
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  top: 1px;
  right: 6px;
}

.identifiers_tabs .react-tabs__tab--selected,
.identifiers_tabs .react-tabs__tab {
  background: transparent !important;
  border-color: transparent !important;
  padding: 0 !important;
  float: left;
  margin: 0;
  border-radius: 0 !important;
  margin-left: 20px !important;
}

.identifiers_tabs .react-tabs__tab input {
  width: 20px;
  height: 20px;
  float: left;
}

.identifiers_tabs .react-tabs__tab-list span {
  float: left;
  margin-right: 20px;
  line-height: 25px;
}

.top_model_head {
  text-align: center;
  width: 100%;
  float: left;
  font-size: 18px;
  font-weight: bold;
}

.title_varients {
  width: 100%;
  float: left;
  text-align: center;
  padding: 15px 0;
}

.selected_image {
  border: solid 5px #3b455f !important;
  margin: 5px !important;
  background: #3d445a !important;
}

.selectImages {
  width: 100%;
  float: left;
  text-align: center;
}

.selectImages a {
  text-align: center;
  display: inline-block;
  margin: 10px;
  border: solid 1px #ccc;
}

.selectImages img {
  float: left;
  width: 50px;
  height: 50px;
}

.radio_variations {
  padding: 20px 0 50px 120px;
}

.radio_variations,
.radio_variations p {
  width: 100%;
  float: left;
}

.radio_variations p span {
  padding: 2px 0;
  float: left;
  font-weight: bold;
}

.radio_variations input {
  width: 17px;
  height: 17px;
  float: left;
  margin: 4px;
}

.variations_save {
  width: 100%;
  float: left;
  text-align: center;
}

.closing {
  padding: 0 !important;
  color: #F44336 !important;
  border: none !important;
  font-size: 20px;
  margin: 0 !important;
  background: transparent !important;
}

.warehouse_input {
  width: 33.33%;
  padding: 0 15px 20px 15px;
}

.warehouse_input span label {
  font-weight: 100;
  padding-bottom: 8px;
}

.warehousename input {
  float: left;
  width: 100%;
  padding: 10px 9px;
  border-radius: 4px;
  border: solid 1px #ccc;
}

.topbar__menu a:hover {
  background: #3d445a !important;
  color: #fff !important;
}

.csv-input {
  outline: none !important;
}

.handletime .css-kj6f9i-menu {
  height: 70px !important;
  overflow: auto;
}

// .productpop
// {
//   position: absolute;
//     top: 0;
//     left: -17px;
// }

.resizeInventoryPop {
  top: 10px;
  position: absolute;
}

.ReactTable .rt-noData {
  z-index: 0 !important;
}

.cancelsub {
  background: #f44336 !important;
  color: #fff !important;
}

.onboardlightbox .form-group {
  margin-bottom: 8px !important;
}

.onboardlightbox .form-group label {
  margin-bottom: 0rem;
}

.branding_whole {
  border: solid 2px #dedede;
  padding: 25px;
  margin-bottom: 20px !important;
}

.branding_whole_left {
  float: left;
  padding: 0 50px 0 0;
  // margin-bottom: 40px;
  // padding: 0;
  // width: 45%;
}

.branding_whole_right {
  float: right;
  padding: 0 0 0 50px;
  // margin-bottom: 40px;
  // padding: 0;
  // width: 45%;
}

.branding_whole_left h4,
.branding_whole_right h4 {
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
}

.branding_div {
  padding: 10px 0 0 0 !important;
  float: left;
}

.branding_div h4 {
  font-weight: bold;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 8px;
}

.branding_left input[type="file"],
.branding_right input[type="file"] {
  margin: 1px 0px !important;
  float: left;
}

.branding_div .form__form-group-field {
  width: 100% !important;
  padding: 0 0 12px 0;
}

.branding_left,
.branding_right {
  float: left;
  padding: 20px 10px 10px 0;
}

.branding_left h4,
.branding_right h4 {
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  // margin-bottom: 10px;
}

.branding_left p,
.branding_right p {
  margin: 0;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 13px;
}


.branding_whole_right_head {
  text-align: center;
  margin: 0;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 13px;
  padding: 15px 0;
}

.brand_registered {
  padding: 20px 0 !important;
}

.trademark_type {
  float: left;
}

.trademark_type_span {
  font-weight: bold;
  float: left;
  width: 100%;
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 10px;
}

.trademark_type ul {
  float: left;
  width: 100%;
}

.trademark_type ul li p {
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 7px;
}

.trademark_type_p {
  text-transform: capitalize;
  font-weight: bold;
  margin: 25px 0;
  float: left;
}

.form_branding_left {
  width: 48% !important;
  display: flex;
  margin: auto;
  float: left;
  padding: 0 15px 0 0;
}

.form_branding_left span input {
  padding: 10px;
  float: left;
  border-radius: 5px;
  border: solid 1px #ccc;
}

.form_branding_right {
  width: 48% !important;
  display: flex;
  margin: auto;
  float: right;
  padding: 0 0 0 15px;
}

.form_branding_right span input {
  padding: 10px;
  float: right;
  border-radius: 5px;
  border: solid 1px #ccc;
}

.form_branding_left span,
.form_branding_right span {
  width: 100% !important;
}

.trademark select,
.css-1hwfws3,
.css-kj6f9i-menu {
  text-transform: capitalize;
}

.branding_left textarea {
  width: 100% !important;
}

.save_btn {
  display: inline-grid;
  margin-top: 20px;
  margin-bottom: 20px;
}

.save_btn button {
  margin: 0 auto !important;
  text-align: center;
  background: #3d445a !important;
  color: #fff !important;
  padding: 5px 35px;
}

.logo_photo {
  width: 90px;
  /* float: left; */
  display: block;
}

.cards_added {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 16px;
}

.cancelsub-div {
  width: 100%;
  float: left;
}

.cancelsub-div .cancelsub {
  margin: 0 20px 0 0 !important;
}

.tabs_market_div {
  width: 100%;
  margin: 35px 15px;
}

.tabs_market {
  border: solid 1px #e4e4e4;
  width: 100%;
  padding: 20px;
}

.list_opti {
  width: 100%;
  float: left;
  border: solid 3px #f3f3f3;
  /* margin: 0 20%; */
  // padding: 25px;
  margin-top: 10px;
}

.list_opti h3 {
  font-weight: bold;
}

.list_opti h4 {
  font-weight: bold;
  margin: 25px 0;
}


.list_opti_span {
  width: 100%;
  float: left;
  margin: 8px 0;
}

.list_opti_div {
  width: 60%;
  float: left;
  padding: 25px;
  margin: 0 20%;
}

.list_opti_table {
  width: 100%;
  float: left;
}

// .list_opti_table div
// {
//   width:100%;
//   float:left;
// }

.list_opti .download_buttons {
  padding-right: 20px;
  float: right;
}

.total_list {
  text-align: right;
  font-size: 20px;
  padding: 10px 20px;
  float: right;
  width: 100%;
}

.purchase_total_list {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.purchase {
  width: auto !important;
  background: #3d445a;
  color: #fff;
  margin: 0 20px;
  padding: 8px 10px;
  font-size: 16px;
  border-radius: 10px;
  float: right;
}

.purchase_p {
  margin: 25px 0;
  font-size: 16px;
  font-weight: 500;
}

.return_app {
  float: none;
  background: #3d445a !important;
  color: #fff !important;
}

.upgradeplan_btn {
  background: #00cfff !important;
  padding: 8px 18px;
  font-size: 20px;
  float: none;
  color: #fff !important;
}

.header_sell {
  float: left;
  width: 100%;
  padding: 12px;
  font-size: 26px;
  font-weight: bold;
}

.header_sell_p {
  padding: 0 20px;
  float: left;
  margin: 0 15px 20px 15px;
}

.closepurchase {
  position: absolute;
  right: 10px;
  color: red;
  top: 10px;
  // font-size: 20px;
  cursor: pointer;
}

.video_btn,
.video_btn:hover {
  float: right;
  background: #3d445a;
  color: #fff;
  padding: 2px 8px;
  margin: 0 !important;
}

.subscription_div {
  text-align: center;
}

.subscription_cancel {
  text-align: center;
  width: 100%;
  float: left;
  border-top: solid 2px #dedede;
  margin-top: 20px;
  padding: 15px;
}

.subscription_cancel a {
  color: red;
  text-transform: capitalize;
  text-decoration: underline;
}


.subscription_div-text {
  text-align: center;
  text-transform: capitalize;
  color: #000 !important;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  float: left;
  padding: 20px 0 10px 0px;
}

.subscription_div {
  width: 100%;
  float: left;
  margin-top: 10px;
  margin-bottom: 5px;
}

.subscription_div .btn {
  background: #3d445a;
  padding: 8px 15px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 0;
}

.sub-div {
  border: solid 2px #dedede;
  text-align: center;
}

.subscription_div span {
  width: 100%;
  padding-left: 55px;
  float: left;
  text-align: center;
  text-transform: capitalize;
}

.sub_span_div {
  width: 480px;
  margin: 0 auto;
  float: none !important;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;

}

.sub_span_div .form-check {
  margin-bottom: 10px !important;
  float: left;
  width: 100%;
}

.subscription_cancel_a {
  padding-bottom: 15px;
  float: left;
  text-align: center;
  width: 100%;
  color: #3d445a !important;
  text-decoration: blink !important;
}


.sub_span_div label {
  width: 100%;
  float: left;
  text-align: left;
}

.sub_span_div input {
  width: 16px !important;
  float: left;
  position: relative;
  margin: 2px 10px 2px 0;
}

.sub_span_div span .form-group {
  float: left;
  width: 100%;
}

.sub_span_div span .form-group textarea {
  float: left;
  margin-left: 20px;
}

.Break_down {
  width: 49%;
  float: left;
  border: solid 2px #ccc;
  padding-bottom: 20px;
}

.Break_down h4 {
  padding: 15px;
  font-size: 20px;
  font-weight: bold;
}

.Break_downs {
  width: 49%;
  float: right;
  border: solid 2px #ccc;
}

.Break_downs h4 {
  padding: 14px;
  font-size: 20px;
  font-weight: bold;
}

.Break_downs .download_buttons,
.Break_down .download_buttons {
  display: none;
}

.Break_downs .rt-thead {
  background: #3d445a;
}

.Break_downs .rt-resizable-header-content {
  color: #fff !important;
  padding: 6px;
  font-size: 14px;
}

.Break_downs .ReactTable {
  height: 275px;
}

.Break_downs .ReactTable .rt-tbody {
  overflow: initial !important;
}

.summary {
  float: left;
  margin-bottom: 30px;
  width: 100%;
}

.summary h3 {
  background: #3d445a;
  text-align: center;
  color: #fff !important;
  padding: 10px;
}

.summary_div {
  width: 100%;
  float: left;
}


.left_summary,
.right_summary {
  width: 50%;
  float: left;
  border: solid 1px #ccc;
  height: 40px;
  padding: 7px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.comming_soon {
  position: fixed;
  width: 100%;
  background: rgba(255, 255, 255, 0.91);
  height: 100%;
  top: 0;
  z-index: 9999;
  text-align: center;
  overflow: hidden;
  font-size: 30px;
  padding-top: 100px;
}

.for_web {
  display: block;
}

.for_mobile {
  display: none;
}

.react-tabs__tab {
  padding: 6px 12px !important;
}

.market-div {
  margin: 0px 0%;
}

.market-div h3,
.market-div p {
  font-weight: 700;
  margin-bottom: 0px;
}

.advertise_bud span {
  font-size: 12px;
}

.advertise_bud h4,
.advertise_bud span {
  font-weight: 700;
  margin-bottom: 10px;
}

.market-div p {
  font-size: 12px;
}


.list_opti h4 {
  margin: 10px 0;
  font-size: 12px;
}

.list_opti_div {
  width: 100%;
  float: left;
  padding: 25px;
  margin: 0 0%;
}

.tab_market_table {
  width: 93% !important;
}

.tab_market_table1 {
  width: 100% !important;
}

.product_bold-text {
  text-transform: capitalize;
  display: block !important;
}

.add-button {
  float: left;
  text-transform: capitalize;
}

.select_date {
  font-size: 12px;
}

.storefront_design h1 {
  text-align: center;
  font-weight: bold;
  margin-top: 40px;
}

.storefront_design_div {
  width: 85%;
  margin: auto;
}

.storefront_design_div_inner {
  border-top: solid 2px #ccc;
  border-bottom: solid 2px #ccc;
  padding: 40px 0;
  float: left;
}

.storefront_design_div_inner h4 {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  color: #000;
}

.small_p {
  width: 100%;
  float: left;
  text-align: center;
  font-weight: bold;
  color: #7d7d7d;
}

.title_top {
  color: #0731ff;
  width: 100%;
  float: left;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  margin: 30px 0 10px 0;
}

.title_top_border {
  height: 9px;
  width: 180px;
  margin: 20px auto;
  display: table;
  background: #000;
}

.title_top_heading {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0;
}

.storefront_design_div_inner .li {
  width: 100%;
  float: left;
  font-size: 18px;
  margin-bottom: 20px;
}

.purchase_div {
  float: left;
  width: 100%;
  text-align: center;
}

.purchase_div button {
  font-size: 20px;
  padding: 6px 15px;
  margin-top: 20px;
}

.agreement_product .download_buttons {
  display: none;
}

.agreement_product_update {
  width: 100%;
  float: left;
  margin-top: 10px;
}


.product_variant_div .image-width {
  width: 100%;
  padding: 5px 15px;
}

.product_variant_div .variant_div_inner {
  float: left;
  width: 48%;
  margin: 1%;
  border: solid 1px #ccc;
  padding-bottom: 15px !important;
  border-radius: 4px;
}

.product_variant_div .variant_div_inner h3 {
  float: left;
  padding: 15px 0 0 15px;
  font-weight: bold;
  width: 100%;
}

.product_variant_div .name_inner {
  padding: 5px 15px;
}

.product_variant_div .name_inner,
.name_inner label {
  width: 100% !important;
  float: left;
  display: inline-block;
}

.product_variant_div .wrapperClassName {
  border: none !important;
}

.product_variant_div .import_div {
  float: left;
  border: solid 2px #ccc;
  margin: 0 15px 15px 15px;
  padding: 8px;
  margin-top: 15px;
  cursor: pointer;
  text-align: center;
  width: 85px;
  height: 85px;
}

.product_variant_div .textare {
  width: 100% !important;
  border: 1px solid rgb(204, 204, 204);
  padding: 10px;
}

.product_variant_div .import_div img {
  display: block;
  margin: auto;
  width: 35px !important;
}

.product_variant_div .import_div span {
  color: #41c3f0;
  font-weight: bold;
  font-size: 15px;
  margin-top: 7px;
}

.product_variant_div .import_div_div {
  width: 100%;
  float: left;
}

.product_variant_div .rdw-editor-toolbar {
  margin: 0 !important;
}

.product_variant_div .rdw-editor-main {
  border: solid 1px #ccc;
  min-height: 137px;
}

.product_variant_div h3 span {
  float: right;
  font-size: 16px;
  color: #41c3f0;
  font-weight: bold;
  padding-right: 15px;
}

.product_variant_div .variant_div_inner_final {
  float: left;
  border: solid 1px #ccc;
  width: 98%;
  margin: 1%;
}

.product_variant_div .variant_div_inner_final h3 {
  padding: 15px 0 0 28px;
}

.product_variant_div .name_inner .checkbox input {
  width: 15px;
  margin: 4px;
}

.product_variant_div .name_inner .checkbox {
  margin-top: 11px;
  float: left;
}

.product_variant_div .name_inner .col {
  width: 25% !important;
  float: left;
}

.product_variant_div .name_inner .col input {
  width: 100%;
  padding: 8px;
  text-align: center;
  border: solid 1px #ccc;
  border-radius: 4px;
}

.product_variant_div .name_inner_detail {
  width: 100%;
}

.product_variant_div .name_inner_detail label {
  width: 100% !important;
}

.product_variant_div .name_inner_detail input {
  width: 100% !important;
  padding: 8px;
  border: solid 1px #ccc;
  border-radius: 4px;
}

.product_variant_div .name_inner_detail button {
  float: left;
  padding: 8px 16px;
  margin-bottom: 25px;
  background: #3d445a !important;
  color: #fff !important;
}

.product_variant_div .name_close {
  margin-top: 18px;
  padding-top: 7px;
}

.product_variant_div .publish {
  float: right;
  border: none;
  padding: 8px 10px;
  margin: 10px;
  background: #3d445a;
  color: #fff;
  border-radius: 8px;
}

.product_variant_div .textare {
  width: 100% !important;
}

.product_variant_div .upload_img_div {
  width: 110px;
}

.product_variant_div .image-upload_file {
  position: relative;
}

.product_variant_div .image_upload_split input {
  border: solid 2px #ccc;
  position: absolute;
  left: 0;
  width: 80px;
  height: 80px;
  opacity: 0;
}

.product_variant_div .image_upload_split {
  width: 80px !important;
  height: 80px !important;
  margin: 0;
  border: solid 2px #ccc;
  text-align: center;
}

.product_variant_div .image_upload_split span {
  font-size: 29px;
  color: #b3b3b3;
  padding-top: 14px;
  float: left;
}

.product_variant_div .upload_img_div .image-width1,
.upload_img_div .image-width2 {
  width: 100%;
}

.product_variant_div .upload_img_div .form__form-group-field {
  padding: 15px 15px !important;
}

.product_variant_div .upload_img_block img {
  background-color: #ccc;
  margin-top: 0px;
  height: 85px;
  width: 85px;
}

.product_variant_div .upload_img_block .upload_img_close {
  height: 15px !important;
  width: 15px !important;
}

.product_variant_div .upload_img_block {
  position: absolute;
  width: 85px !important;
  display: flex;
  // margin: 0 auto;
  float: left;
}

.product_variant_div .images_div {
  padding-bottom: 1px;
  border: none !important;
}

.product_variant_div .video_import {
  width: 80px;
  height: 80px;
  position: 'relative';
  padding: 0;
}

.product_variant_div .video_import .video_import1 {
  margin: 0;
  float: left;
  width: 100% !important;
  height: 80px;
}


.product_variant_div .video_import .upload_img_close {
  width: 15px !important;
}

.rdw-block-wrapper,
.rdw-fontsize-wrapper,
.rdw-fontfamily-wrapper,
.rdw-text-align-wrapper,
.rdw-colorpicker-wrapper,
.rdw-link-wrapper,
.rdw-embedded-wrapper,
.rdw-emoji-wrapper,
.rdw-image-wrapper,
.rdw-remove-wrapper,
.rdw-history-wrapper {
  display: none !important;
}

.product_variant_div .identifiers_tabs .react-tabs {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.product_variant_div .labelgray {
  color: #999;
}

.product_variant_div .labelgray span {
  color: #000;
}

.sketch-picker {
  margin-top: 50px;
}

.swatch_box div {
  width: 20px !important;
  height: 20px;
  opacity: 0;
}

.swatch_box {
  position: absolute;
  margin-top: 14px;
  margin-left: 5px;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.youtube_url {
  width: 100%;
  padding: 10px;
  border: solid 1px #ccc;
}

.modal-header,
.modal-footer {
  border: none !important;
}

.modal-body {
  border: none;
  padding: 0px 15px 0 15px;
  margin-bottom: 16px;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.add_product_btn {
  padding: 7px;
  margin-right: 15px;
  background: #000 !important;
  color: #fff !important;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
}

.add_product_btn span {
  color: #000 !important;
  background: #fff;
  width: 16px;
  float: left;
  height: 16px;
  border-radius: 16px;
  margin-right: 5px;
}

.css-1bqbnod,
.css-1m2uvap {
  z-index: 99999 !important;
}

.toggle_field {
  width: 16% !important;
  float: left;
  margin-top: 10px;
}

.payment_datepicker .react-datepicker-wrapper {
  width: 200px;
  float: right;
  text-align: right;
  margin-bottom: 15px;
}

.payment_datepicker .react-datepicker-wrapper .react-datepicker__input-container {
  float: right;
  width: 200px;
}

.datepicker_payment {
  width: 100%;
  float: left;
}

.datepicker_payment .datepicker_payment_inner {
  width: 200px;
  float: right;
}

.datepicker_payment .react-datepicker__input-container input {
  width: 100% !important;
  padding: 10px;
  border: solid 1px #ccc;
  border-radius: 5px;

}

.channal_comming_soon {
  background: rgba(255, 255, 255, 0.95);
  width: 100%;
  float: left;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 23px;
  padding-top: 70px;
}

.redPrioritycolor {
  width: 15px;
  height: 15px;
  background: red;
  margin: 0 auto;
  display: inline-block;
  border-radius: 2px;
}

.orangePrioritycolor {
  width: 15px;
  height: 15px;
  background: orange;
  margin: 0 auto;
  display: inline-block;
  border-radius: 2px;
}

.yellowPrioritycolor {
  width: 15px;
  height: 15px;
  background: yellow;
  margin: 0 auto;
  display: inline-block;
  border-radius: 2px;
}

.blackPrioritycolor {
  width: 15px;
  height: 15px;
  background: #000;
  margin: 0 auto;
  display: inline-block;
  border-radius: 2px;
}

.emailh2 {
  font-size: 20px !important;
}

// .branding_left input,.branding_right input
// {
//   margin: 10px 0 0 0!important;
// }

.tax_tab {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}

.tax_tab .form__form-group-field {
  width: 33.33% !important;
}

.tax_tab .form__form-group-field {
  height: 100px !important;
}

.tax_tab label span {
  color: #41c3f0;
  font-size: 10px;
  text-decoration: underline;
}

.w-9 {
  float: left !important;
}

.react-date-picker__wrapper input {
  min-width: auto !important;
  height: auto !important;
  border: none !important;
  padding: 7px 10px !important;
}

.react-date-picker {
  width: 100%;
  border: solid 1px #ccc;
  border-radius: 3px;
}

.react-date-picker__wrapper {
  height: 38px;
  border: none !important;
}

.react-date-picker__calendar-button {
  float: right;
  right: 0;
  position: absolute;
  top: 6px;
}

.w-9-upload {
  width: 206px;
  height: 30px;
  float: left;
  position: relative;
  background: #3d445a !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eaeaea', GradientType=0) !important;
  border: solid 1px #d9d9d9 !important;
  color: #ffffff !important;
  margin-left: 25px;
  margin-right: 0px;
  border-radius: 8px !important;
}

.w-9-upload p {
  color: #ffffff !important;
}

.w-9-upload input[type="file"] {
  width: 206px;
  margin: 0;
  position: absolute;
  top: 0;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  cursor: pointer;
}

.w-9-upload p {
  padding: 5px;
  text-align: center;
}

.variant_div_inner ul li {
  float: left;
  width: 100%;
  margin-left: 20px;
  margin-top: 8px;
  margin-bottom: 7px;
}

.ReactModal__Overlay {
  z-index: 99999 !important;
}

.profile_sync_table {
  height: 505px;
  overflow: auto;
  float: left;
  width: 100%;
}

.profile_sync_table .-pageInfo,
.profile_sync_table .-pageSizeOptions {
  width: auto !important
}

.profile_sync_table .-pageInfo span {
  float: none !important;
}

.profile_sync_table .rt-td img {
  width: 27px !important
}

.profile_sync_table .rt-td .btn {
  padding: 2px 15px;
  margin-bottom: 0;
}

.agreement_product_update1 {
  width: auto !important;
  float: right !important;
  margin-top: 3px;
  margin-right: 19px;
}

.planing_div {
  width: 100%;
  float: left;
}

.planing_div .planing {
  width: 32.33%;
  float: left;
  border: solid 2px #efefef;
  margin: 0.5% 0.5% 4% 0.5%;
  text-align: center;
  padding: 20px;
  border-radius: 6px;
}

.planing_div .planing h3 {
  font-size: 34px;
  font-weight: bold;
  color: #3d445a !important;
}

.planing_div .planing h4 {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 20px;
}

.planing .btn {
  background: #3d445a;
  color: #fff;
  margin-top: 20px;

}



.activeplan {
  background: #efefef;
  box-shadow: 1px 6px 10px #ccc;
  padding: 35px 0 !important;
  margin-top: 0.8% !important;
}

.planing_div .react-tabs__tab-list {
  text-align: center;
  border-bottom: none !important;
  margin-top: 50px;
}

.planing_div .react-tabs__tab--selected {
  background: #3d445a;
  border-color: none;
  color: black;
  border-radius: 6px;
  color: #fff;
}

.list_opti_div_head {
  text-align: center;
  margin: 0 100px;
  float: left;
  width: calc(100% - 200px);
}

.list_opti_div_head h4 {
  color: #8c8c8c;
  width: 67%;
  margin: 12px auto;
}

.sell_more h3 {
  font-size: 20px;
}

.sell_more p {
  margin: 0;
  color: #8c8c8c;
}

.sell_more {
  margin: 0 100px;
  float: left;
  width: calc(100% - 200px);
}

.sell_more_div {
  text-align: center;
  margin: 0px 85px;
  float: left;
  width: calc(100% - 170px);
}

.sell_more_div div {
  text-align: center;
  float: left;
  margin-top: 40px;
  height: 290px;
}

.sell_more_div div img {
  width: 100%;
}

.sell_more_div div h4 {
  font-size: 20px;
}

.sell_more_div div p {
  color: #8c8c8c;
  font-size: 13px;
  height: 50px;
}

.ads-market-div {
  text-align: center;
  margin: 0px 85px;
  float: left;
  width: calc(100% - 170px);
}

.paypal_btn_container {
  width: 100%;
  text-align: center;
  margin-top: 2.2%;
}

.ads-market-div div {
  float: left;
  margin-top: 55px;
  margin-bottom: 55px;
}

.ads-market-div div h2 {
  text-align: left;
  font-weight: bold;
}

.ads-market-div div p {
  text-align: left;
  font-size: 20px;
}

.ads-market-div div button {
  background: #6e85ff;
  float: left;
  margin-top: 20px;
  border: none;
  color: #fff;
  padding: 12px 25px;
  font-size: 18px;
  border-radius: 4px;
}

.ads-market-main {
  background: #f4f5f7;
  margin: 0 0px !important;
  width: 100%;
}

.market_user_div {
  width: 100%;
  float: left;
  margin: 15px 0;
}

.market_user {
  width: 100%;
  float: left;
}

.shipping_head {
  border: solid 1px #ccc;

}

.shipping_head_inner {
  padding: 10px;
  background: #3d445a;
  color: #fff;
}

.shipping_head_status {
  float: left;
}

.shipping_head_status img {
  width: 180px;
  padding: 10px 0;
}

.shipping_head_order {
  width: 100%;
  float: left;
  padding: 5px;
  font-size: 16px;
}

.shipping_head_order span {
  font-weight: bold;
  margin-right: 10px;
}

.shipping_head_select {
  float: left;
}

.shipping_head_select .css-bg1rzq-control,
.shipping_head_select .css-1szy77t-control {
  background: #3d445a;
}

.shipping_head_select .css-151xaom-placeholder,
.shipping_head_select .css-dvua67-singleValue {
  color: #fff !important;
}

.shipping_head_second {
  background: #edeef0;
  padding: 20px 0;
  float: left;
}

// .shipping_head_second div
// {
//   float: left;
// }

.shipping_head_select button {
  background: #3d445a;
  color: #fff;
  width: 100%;
  border-radius: 4px;
  border: none;
  padding: 11.5px 0;
}


.search_shipping {
  float: right;
  margin-top: 20px;
  // margin-top: 20px;
}

.nodatafound {
  text-align: center;
  font-size: 20px;
  margin-top: 100px;
}

.search_shipping input {
  border-radius: 20px 0 0 20px;
  width: 80%;
  float: left;
}

.search_button {
  width: 20%;
  padding: 7.5px 10px;
  background: #edeef0;
  border-radius: 0 30px 30px 0;
}

.order_summary {
  width: 100%;
  float: left;
  margin-top: 30px;
}

.order_summary_inner {
  background: #fff;
  border: solid 2px #3d445a;
  border-radius: 25px;
  padding: 0;
  float: left;
  // min-height: 245px;
}

.order_summary h2 {
  background: #3d445a;
  border-radius: 21px 21px 0 0;
  color: #fff;
  font-size: 16px;
  padding: 3px 20px;
  font-weight: bold;
}

.order_summary h2 button {
  float: right;
  margin-top: 6px;
  background: transparent;
  color: #fff;
  border: none;
  font-weight: bold;
}

.order_summary_inner_div {
  float: left;
  padding: 6px 10px;
}

.order_summary_inner_div img {
  width: 26px;
}

.order_summary_inner_div .id {
  margin-left: 5px;
  font-weight: bold;
  margin-right: 5px;
}

.bottom_summary {
  margin: 10px 20px;
  border-top: solid 1px #ccc;
  width: calc(100% - 40px);
  text-align: center;
}

// .css-1pcexqc-container
// {
//   width: 150px;
//   display: flow-root;
//   margin: 0 auto 10px auto;
//   border: solid 1px #3d445a;
//   border-radius: 4px;
// }

.order_summary_inner_div span {
  float: left;
}

.Buyer .buyer_img {
  width: 26px;
}

.Buyer .buyer_label {
  width: 106px;
  line-height: 24px;
  padding-left: 7px;
}

.Buyer .buyer_input {
  width: calc(100% - 135px);
  word-break: break-all;
}

.Buyer .buyer_input input {
  padding: 0;
  height: 23px;
  border: none;
  border-bottom: solid 1px #ccc;
  border-radius: 0;
}

.ship_btn {
  background: #fff !important;
  color: #3d445a !important;
  font-size: 10px;
  margin: 5px 4px;
  padding: 5px 2px;
  border-radius: 5px;
}

.tablehead div,
.tablebody div {
  float: left;
}

.tablefull {
  float: right;
}

.tablefull .tablehead {
  float: left;
  width: 100%;
  border-bottom: solid 1px #ccc;
  font-weight: bold;
  padding: 7px 0;
  margin-bottom: 10px;
}

.tablefull .tablebody {
  float: left;
  width: 100%;
}

.order_header .order_item {
  width: 60%;
  float: left;
  font-weight: bold;
  padding: 10px;
  font-size: 15px;
  border: solid 1px #3d445a;
}

.order_header .order_unit {
  width: 10%;
  float: left;
  font-weight: bold;
  padding: 10px;
  font-size: 15px;
  border: solid 1px #3d445a;
}

.order_header .order_qty {
  width: 15%;
  float: left;
  font-weight: bold;
  padding: 10px;
  font-size: 15px;
  border: solid 1px #3d445a;
}

.order_header .order_total {
  width: 15%;
  float: left;
  font-weight: bold;
  padding: 10px;
  font-size: 15px;
  border: solid 1px #3d445a;
}

.order_body .order_item {
  width: 60%;
  float: left;
  padding: 10px;
  font-size: 15px;
  border: solid 1px #3d445a;
  border-bottom: none;
}

.order_summary_inner .order_header .order_item,
.order_summary_inner .order_body .order_item {
  border-left: none !important;
}


.order_summary_inner .order_header .order_total,
.order_summary_inner .order_body .order_total {
  border-right: none !important;
}

.order_summary_inner .order_body {
  display: flex !important;
  float: left;
}


.order_body .order_unit {
  width: 10%;
  float: left;
  padding: 10px;
  font-size: 15px;
  border: solid 1px #3d445a;
  border-bottom: none;
}

.order_body .order_qty {
  width: 15%;
  float: left;
  padding: 10px;
  font-size: 15px;
  border: solid 1px #3d445a;
  border-bottom: none;
}

.order_body .order_total {
  width: 15%;
  float: left;
  padding: 10px;
  font-size: 15px;
  border: solid 1px #3d445a;
  border-bottom: none;
}


.activity {
  float: left;
  width: calc(100% - 40px);
  margin: 20px;
}

.activity div {
  float: left;
  height: 100px;
}

.orders_details_div .activity div {
  float: left;
  height: 67px !important;
}

.orders_details_div .activity .line {
  height: 78px !important;
  margin-top: 10px;
}

.orders_details_div .activity .dot {
  margin-top: 6px;
}


.activity .dot {
  width: 15px;
  height: 15px;
  background: #3d445a;
  // margin: 0px 10px;
  float: right;
  display: inline-block !important;
}

.activity .line {
  width: 2px;
  background: #3d445a;
  display: inline-block;
  position: absolute;
  right: 31px;
  height: 100px;
}

.activity_inner .col-md-2 {
  padding: 0 25px 0 0px;
}

.dot_header {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.activity_date {
  font-size: 16px;
  font-weight: bold;
}

.dot_para {
  width: 100%;
  float: left;
}

.dot_date {
  width: 100%;
  float: left;
  font-size: 12px;
  font-weight: bold;
}

.final .dot,
.final .line,
.final .dot_para,
.final .dot_data {
  display: none;
}

.final {
  height: 35px !important;

}

.shipping_info .activity_inner {
  padding: 0;
  margin: 3px 0 !important;
}

.shipping_info .activity_inner .col-md-4,
.shipping_info .activity_inner .col-md-8 {
  padding: 0;
}

.shipping_info .activity div {
  height: auto !important;
}

.shipping_info .activity_date {
  float: left;
  width: 100%;
  font-size: 14px;
  line-height: 38px;
}

.shipping_info {
  float: left;
}

.shipping_info .order_summary {
  float: left;
  padding-left: 0;
}

.order_items {
  float: left;
}

.order_items .order_summary {
  padding-right: 0;
}

.shipping_info .css-1pcexqc-container,
.shipping_info .css-bg1rzq-control {
  width: 100%;
}

.shipping_info .activity_inner button {
  background: #ffff01;
  border: none;
  width: 100%;
  border-radius: 30px;
  padding: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #3d445a;
  margin-top: 10px;
}

.shipping_info .weight input,
.shipping_info .weight .css-1pcexqc-container {
  width: 26% !important;
  font-size: 12px;
  float: left;
}

.shipping_info .weight .css-1szy77t-control {
  width: 100%
}

.shipping_info .weight span {
  width: 10%;
  font-size: 14px;
  float: left;
  font-weight: bold;
  line-height: 37px;
}

.shipping_info .weight .weightinput {
  height: 40px;
  border: solid 1.5px #3d445a;
}

.shipping_info .shippingsize input {
  height: 38px;
  border: solid 1.5px #3d445a;
  margin: 0 2px;
  float: left;
  width: calc(57.33% - 60px);
  font-size: 14px;
  margin-bottom: 10px;
}

.othershipping input[type="checkbox"] {
  width: 20px !important;
  height: 20px !important;
}


.othershipping .activity_inner1 span {
  padding: 5px 10px;
  float: left;
}

.ordernotes {
  float: left;
  width: 100%;
  padding: 4px 0;
}

.shipping_info .css-1szy77t-control {
  width: 100%;
}

.shipping_info .css-bg1rzq-control {
  // border: solid 1.5px #3d445a!important;
  border-color: #3d445a !important;
  width: 100% !important;
  margin: 0 auto;
}

.order_summary_inner .css-1pcexqc-container {
  width: 50%;
  margin: 0 auto;
}

.shipping_info .css-1pcexqc-container {
  width: 100% !important;
}

.import_div_div {
  &.images-upload {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .product-img {
      position: relative;
      width: 85px;
      height: 85px;

      .upload_img_close {
        width: 15px;
        height: 15px;
      }
    }

    .drag-context {
      width: calc(100% - 140px);
    }
  }
}

.proinfo_indentify {
  border: none !important;
  margin: 0 !important;
  width: 50% !important;
}

.sugges {
  float: left;
  border: solid 1px #ccc;
  width: 96%;
  margin: 2%;
  border-radius: 5px;
  padding-bottom: 10px !important;
}

.discount_level {
  width: 100%;
  float: left;
  text-align: left;
  margin: 20px 0 0 0 !important;
}

.discount_level label {
  font-weight: bold;
  font-size: 18px;
}

.discount_level div {
  width: auto !important;
  float: none;
  margin: 0;
}

.overlayIntro.resizePop_brand .react-user-tour-container>div:first-child {
  margin-left: 36% !important;
  margin-top: 135px !important;
}

.overlayIntro.productpop2 .react-user-tour-container>div:first-child {
  min-height: auto !important;
  height: 170px !important;
}

.css-es53b3-multiValue,
.css-kj6f9i-menu {
  text-transform: initial !important;
}

.list_div {
  float: left;
  width: 100%;
  text-align: left;
  line-height: 40px;
  font-size: 18px;
}

.purchase_total_list {
  margin-bottom: 50px;
}

.billing_agree {
  font-weight: 600;
  font-size: 16px !important;
  text-align: center !important;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 2px #909090;
}

.billing_agree_bottom {
  border-top: solid 2px #909090;
  text-align: center !important;
  font-size: 18px !important;
  line-height: 50px;
  color: #909090 !important;
}

.billing_agree_bottoms {
  text-align: center !important;
  font-size: 18px !important;
  line-height: 50px;
  color: #909090 !important;
  width: 100%;
  float: left;
  margin: 0 auto 10px auto;
}

.billing_agree_middle {
  text-align: center !important;
  font-size: 18px !important;
  color: #909090 !important;
  margin: 0;
}

.auto_billing {
  text-align: center !important;
  margin: 0 auto !important;
  width: 100%;
  display: inline-grid;
}

.auto_billing button {
  background: #f6c43b !important;
  color: #000 !important;
  border-radius: 20px !important;
  text-align: center;
  margin: 6px auto !important;
  padding: 7px 18px !important;
}


.billing_agree_bottoms span,
.billing_agree_bottoms input {
  width: 100px;
  display: inline;
  margin: 0 10px;
}

.billing_agree_middle {
  float: left;
  width: 100%;
}

.back_products {
  margin: 20px 0;
  padding: 8px 10px;
  border: solid 1px #ccc;
  border-radius: 5px;
  color: #3d445a;
}

.orders_details {
  padding: 0 !important;
}

.order_dots {
  float: left;
  font-weight: bold;
  padding-right: 10px;
}

.orders_details div,
.orders_details p {
  float: left;
  margin: 0 !important;
  font-size: 14px;
  line-height: 24px;
}

.orders_details_div .modal-title {
  font-size: 20px;
  font-weight: bold;
}

.orders_details_div {
  margin-top: 85px;
  width: 100%;
  max-width: 88%;
}

.orders_details_label {
  width: 120px;
  float: left;
  font-weight: bold;
}

.orders_details_label_next {
  width: calc(100% - 140px);
  word-break: break-all;
  float: left;
}

.modal-backdrop {
  background-color: #000 !important;
}

.order_details_close {
  margin: 0;
  padding: 2px 5px;
  float: right;
}

.orders_details_div .modal-title {
  width: 100%;
}

.fix_height {
  width: 100%;
  float: left;
}

.select_plan div {
  margin: 0 !important;
}

.select_plan {
  line-height: 20px !important;
  font-size: 14px !important;
}

.select_plan span {
  width: auto !important;
  display: block !important;
  margin: 0 0px !important;
}

.select_plan .css-1pcexqc-container,
.select_plan .css-bg1rzq-control,
.select_plan .css-1szy77t-control {
  width: 200px;
}

.adjust {
  padding: 8px 0 8px 15px;
}

.error_plan {
  float: left;
  color: #3d445a;
  font-size: 18px;
  margin: 14px 0;
  width: 100%;
  font-weight: 500;
}

.agreement_split {
  width: 20%;
  float: left;
  margin: 0 !important;
  padding: 0 10px;
}

.agreement_split label {
  margin-bottom: 0 !important;
}

.agreementss .react-datepicker-wrapper {
  width: 180px;
}

.billing_agree_bottoms_select .css-1pcexqc-container,
.billing_agree_bottoms_select .css-bg1rzq-control,
.billing_agree_bottoms_select .css-1szy77t-control {
  width: 265px !important;
}

.billing_agree_bottoms_select {
  margin: 0 10%;
  width: 80%;
}

.precentage {
  float: left;
  font-size: 14px !important;
  color: #949494;
  text-align: center !important;
  width: 80%;
  margin: 20px 10%;
}

.editimg_btn {
  margin: 0;
  padding: 0px;
  font-size: 10px;
  margin-left: 10px;
}

.editimg_btn img {
  padding: 3px;
}

.paypal_div div {
  margin: 0 !important;
}

.paypal_div {
  margin: 10px !important;
}

.paypal_div .css-1pcexqc-container,
.paypal_div .css-bg1rzq-control,
.paypal_div .css-1szy77t-control {
  width: 100%;
}

.multisell {
  display: inline-block;
  margin: 0 auto;
}

.multisell .css-kj6f9i-menu {
  overflow: auto;
  // height: 150px;
  z-index: 9999 !important;
}

.Toastify__toast-container {
  z-index: 999999 !important;
}

.suscribe_button {
  padding: 5px 15px;
  background: #f6c43b !important;
  color: #01206a !important;
  border-radius: 20px;
  margin-top: 15px;
  font-weight: bold;
}

.marketplace_img img {
  width: 70px !important;
  float: left;
  margin: 5px;
}

.billings_agree .rt-tbody {
  height: 250px;
  overflow: scroll;
}

.billings_agree .ReactTable .rt-thead.-header {
  box-shadow: none !important;
  width: calc(100% - 17px);
}

.marketplaces_sku {
  width: 100%;
  float: left;
  margin: 15px 0 !important;
}

.marketplaces_sku label {
  width: 100%;
  float: left;
}

.marketplaces_sku .rangeslider {
  float: left;
  width: 100%;
}

.marketplaces_sku img {
  height: 50px;
  width: auto;
}

// .paypal-buttons
// {
//   max-width: 50%!important;
//   margin: 0 25%!important;
// }

.agree_paypal {
  // max-width: 50%!important;
  // margin: 0 25%!important;
  padding-bottom: 10px !important;
  float: left;
  width: 90%;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #3F4B5B !important;
}

// .marketplaces_sku button
// {
//   border: solid 1px #3d445a;
//   background: #fff!important;
// }

.marketplaces_sku button {
  padding: 0 !important;
}

.active-sell {
  background: #fff !important;
  border: solid 2px #3d445a !important;
}

.subscription_div_cancelled span {
  padding: 0 !important;
  width: 30%;
  text-align: left;
  /* display: contents; */
  margin: 4px 35%;
}

.subscription_div-text-span {
  padding: 0 !important;
  width: 30%;
  margin: 10px 35%;
  text-align: left !important;
}

.upgrade_plan {
  background: #009788 !important;
  color: #fff !important;
  padding: 7px 10px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  margin: 20px 20px 20px 0 !important;
}

.cancel_plan {
  background: #dc3546 !important;
  color: #fff !important;
  padding: 7px 10px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  margin: 20px 0 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #3d445a !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #3d445a !important;
}

.carousel .slide {
  background: #fff !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: transparent !important;
}

.carousel .slide h1 {
  font-size: 24px;
}

.carousel .slide p {
  font-size: 16px;
  color: #8c8c8c;
}

.carousel .slide {
  padding: 35px !important;
}

.carousel .slide button {
  background: #3d445a;
  margin-top: 35px;
  color: #fff !important;
}

.plus_plus {
  position: absolute;
  top: 0;
  right: 0;
  background: #3d445a;
  color: #fff;
  padding: 6px 10px;
}

.plus_border {

  border-top: solid 3px #3d445a !important;

}

.loginpopup_img {
  background-color: #2e3444;
  width: 100%;
  float: left;
}

.loginpopup_img img {
  width: 260px;
  margin-bottom: 20px;
  padding: 15px;
}

.UpgradePopup_img {
  background-color: #2e3444;
  width: 100%;
  float: left;
}

.UpgradePopup_img img {
  width: 260px;
  margin-bottom: 20px;
  padding: 15px;
}

.address {
  max-width: 252px;
  margin: auto;

  h5 {
    font-size: 18px;
    margin-bottom: 1.75rem;
    font-family: 'Barlow', sans-serif;
    color: #505050;
    font-weight: 500;
  }

  p,
  a {
    margin-bottom: 1rem;
    font-size: 17px;
    color: #505050;
    line-height: 1.5rem;
    display: block;
  }

  a {
    margin-bottom: 0.5rem;
  }
}


.chatIcon {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 10;
}

.questionMark {
  position: fixed;
  right: 20px;
  top: 10px;
  z-index: 10;
  cursor: pointer;

  img {
    width: auto;
    border-radius: 50%;
  }
}

@media screen and (max-width: 1102px) {
  .right_side_sales {
    width: 100%;
    position: relative !important;
  }

  .sidebar__link {
    padding: 31px 7px !important;
  }

  .sidebar__link p {
    font-size: 13px !important;
  }

  .menuslider {
    width: 170px;
  }

  .sidebar {
    left: 162px;
    width: calc(100% - 280px) !important;
  }

  .heading_search {
    width: 137px !important;
    margin-top: 25px !important;
    margin-right: 0;
    margin-left: 0 !important;
  }

  .sidebar__block {
    float:  !important;
  }
}

@media screen and (max-width: 992px) {
  .sidebar {
    z-index: 99999 !important;
    position: fixed !important;
    margin-top: 80px !important;
    left: -260px !important;
    width: 260px !important;
    height: 100% !important;
  }

  .sidebar.sidebar--show {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    left: 0 !important;
  }

  .topbar__button {
    height: 80px !important;
    border: none !important;
    padding: 10px;
    position: absolute;
    top: 0;
    z-index: 99999;
    display: block !important;
  }

  .scroll-content div {
    height: 100%;
  }

  .scroll-content,
  .sidebar__block {
    height: 100% !important;
    overflow: hidden;
  }

  .menuslider {
    margin-left: 60px;
  }

  .sidebar__block a {
    float: left;
    width: 100%;
  }

  .heading_search .sidebar__block a button,
  .heading_search .sidebar__block a {
    float: left;
    width: 20% !important;
  }

  .heading_search {
    width: 87% !important;
    padding-top: 20px !important;
  }

  .heading_search button,
  .heading_search a {
    width: 20% !important;
  }

  .heading_search button {
    width: 100% !important;
  }

  .sidebar__wrapper--desktop {
    height: 100%;
  }

  .sidebar--show {
    height: calc(100% - 80px);
  }

  .card_body_left,
  .card_body_right {
    width: 100% !important;
    text-align: center;
    float: left;
  }

  .sidebar__link {
    padding: 12px 7px !important;
    height: 40px;
  }

  .heading_search {
    margin-top: 0px !important;
    margin-right: 20px;
    height: 33px;
  }

  .sidebar__link p {
    font-size: 10px !important;
  }

  .menuslider {
    width: 150px !important;
  }

  .sidebar__link p {
    font-size: 13px !important;
  }

  .card_body_right img {
    float: none !important;
  }

  .pie_graph,
  .inventory_status {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    margin-bottom: 20px !important;
  }

  .download_buttons .from_date {
    max-width: 50% !important;
    flex: 0 0 50% !important;
  }

  .from_to1 {
    width: 100%;
  }

  .from_to1 .from_date {
    width: 50%;
    margin-bottom: 20px;
  }

  .search_filters {
    flex: 0 0 30% !important;
    padding: 0 !important;
    max-width: 30% !important;
  }

  .chart_head {
    width: 100% !important;
  }

  .right_side_sales {
    position: relative !important;
    width: 100% !important;
    top: 0 !important;
    border: none !important;
  }

  .salesgraphs .recharts-wrapper {
    width: 100% !important;
  }

  .card__title .search {
    margin-bottom: 10px !important;
  }

  .salesgraphs .recharts-wrapper {
    width: 100% !important;
  }

  .branding_whole_left,
  .branding_whole_right {
    padding: 0 !important;
  }
}

// @media screen and (max-width: 768px) {
//   .branding_whole_left,.branding_whole_right
//   {
//     padding:0!important;
//   }
// }

@media screen and (max-width: 575px) {

  * {
    font-size: 11px;
  }

  .sidebar {
    left: 0 !important;
    height: 100% !important;
  }

  .sidebar__scroll {
    height: 100%;
  }

  .sidebar__block {
    height: auto !important;
  }

  .menuslider {
    width: 150px !important;
  }

  .sidebar__link {
    padding: 12px 7px 10px 7px !important;
  }

  .sidebar__link p {
    font-size: 14px !important;
  }

  .sidebar__block a {
    width: 100%;
  }

  .container__wrap {
    padding-top: 60px;
  }

  .topbar__button--mobile {
    background: #fff !important;
  }

  .sidebar {
    z-index: 99999 !important;
    position: fixed !important;
    margin-top: 80px !important;
    width: calc(100% - 120px) !important;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .top_profilename .topbar__avatar-name {
    display: none !important;
  }

  .generalinfodiv .leftmain,
  .generalinfodiv .rightmain {
    width: 100% !important;
  }

  .createprogress {
    padding-left: 30px;
  }

  .generalinfodiv h5 {
    margin-top: 25px;
    float: left;
  }

  .addforms_div {
    padding: 25px 15px !important;
  }

  .addforms_div .firstlastname .left {
    width: 100%;
  }

  .addforms_div input,
  .examresults .examresults_title {
    width: 70% !important;
  }

  .generalinfo {
    width: 100% !important;
    padding: 0 10px;
  }

  .addform .form__form-group-field label {
    width: 100%;
  }

  .addform .form__form-group-field p {
    width: 100%;
    font-size: 10px;
  }



  .addform {
    padding: 15px;
  }

  .generalinfodiv h5 {
    margin-top: 15px;
  }

  .patient_info input,
  .patient_info textarea {
    width: 100% !important;
    font-size: 11px;
  }

  .uploaddiv input {
    width: 70% !important;
  }

  .firstlastname .left,
  .firstlastname .right {
    margin-bottom: 0 !important;
  }

  .createprogressspan p {
    left: -28%;
  }



  .casestep1 {
    padding: 10px;
  }

  .patient_info h5 {
    margin-bottom: 0px;
  }

  .general__info__div .general__info .form__form-group-field label {
    width: 20%;
  }

  .general__info__div .examresults_title,
  .general__info__div input {
    width: 60% !important;
  }

  .last_saffix {
    float: right !important;
  }

  .general_info_s {
    width: 100%;
    padding: 5px 0;
  }

  .general_info_s label {
    width: 50% !important;
    float: left !important;
  }

  .general_info_s input {
    width: 30% !important;
    float: left !important;
  }

  .general_info_s span {
    width: 20% !important;
    float: left !important;
  }

  .addforms {
    margin-top: 70px;
  }

  .formbuttons button {
    width: 80px;
  }

  .general--table .Instructions .table_div {
    height: auto;
  }

  .dashboard_top .card_body_left,
  .dashboard_top .card_body_right,
  .dashboard_top .card_body_right img {
    width: 100%;
    margin-top: 0;
  }

  .card__title {
    margin: 0
  }

  .users .card {
    width: 98% !important;
  }

  .firstlastname .left,
  .firstlastname .right {
    width: 100%;
  }

  .addform .form__form-group-field input {
    width: 100%;
  }

  .formsuser .form__form-group-field {
    margin: 0px auto;
  }

  .addform .form__form-group-field label {
    padding-top: 5px !important;
  }

  textarea {
    width: 100% !important;
  }

  .topbar__button {
    height: 80px !important;
    border: none !important;
    padding: 10px;
    position: absolute;
    top: 0;
  }

  .topbar__button {
    background-color: #ccc;
  }

  .container__wrap .load__icon {
    left: 0 !important;
  }

  .menuslider {
    padding-left: 0px;
    width: 140px !important;
  }

  .from_to1 {
    width: 100%;
  }

  .from_to1 .from_date {
    width: 50%;
  }

  .from_date label {
    width: 100%;
  }

  .search {
    width: 100%;
    float: right;
  }

  .search_filters {
    flex: 0 0 50% !important;
    padding: 0 !important;
    max-width: 50% !important;
  }

  .form__form-group-field {
    width: 100%;
  }

  .reports_left,
  .reports_right {
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  .chart-wrapper canvas {
    width: 100% !important;
    height: auto !important;
  }

  .dashboard_top .card_body_left_top {
    font-size: 15px !important;
  }

  .dashboard_top .card {
    padding: 2px !important;
  }

  .scroll-content {
    height: auto !important;
  }

  .card-body {
    padding: 10px 5px;
  }

  .container__wrap {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .bold-text a {
    display: inline-flex;
    margin-bottom: 10px;
  }

  .btn {
    font-size: 12px !important;
  }

  .identifiers {
    width: 100%;
    float: left;
    margin: 0px;
    margin-top: 10px;
  }

  .form__form-group-field {
    padding: 4px 15px;
  }

  .rdw-option-wrapper {
    padding: 0px !important;
    min-width: 20px !important;
    height: 16px !important;
    margin: 0 2px !important;
  }

  .addbtn_guide {
    position: initial;
  }

  .image_div_center {
    padding: 0 !important;
  }

  .image_div_center .image-width1 {
    width: 100% !important;
  }

  .image_uploadhead {
    width: 100%;
    float: left;
    padding-top: 0 !important;
  }

  .image_upload_split {
    margin-top: 0;
    width: 100% !important;
  }

  .image-width-delete {
    width: 90% !important;
    margin-right: 5% !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .Toastify__toast-container {
    width: 90vw;
    left: 11px;
    margin: 0;
    z-index: 999999 !important;
  }

  .Toastify__toast-container--top-right {
    top: 3em !important;
    z-index: 999999 !important;

  }

  .responsive_graph {
    width: 100%;
  }

  .right_side_sales span {
    padding: 4px 0;
    width: 50%;
  }

  .search_orders1 {
    width: 100% !important;
  }

  .profile_select {
    float: right;
    width: 100%;
  }

  .profile_select .form-group {
    float: left !important;
    width: 100% !important;
  }

  .uploaddiv {
    width: 100%;
  }

  .market-div {
    max-width: 98%;
    width: 98%;
  }

  .ReactModal__Content--after-open {
    width: 100%
  }

  .form_footer {
    padding-bottom: 0;
  }

  .text-left {
    margin-right: 15px;
  }

  .address {
    max-width: 100% !important;
    margin: 0 !important;
  }

  .address h5 {
    font-size: 14px;
  }

  .address p,
  .address a {
    font-size: 12px;
  }

  .card__title:not(:first-child) {
    margin-top: 0 !important;
  }

  .prefix,
  .suffix {
    top: 25px;
  }

  .dashboard_top .card-body {
    padding: 2px;
    text-align: center;
  }

  .dashboard_top {
    padding: 0 13px 13px !important;
  }

  .page-title {
    padding-bottom: 0 !important;
  }

  .inventory_status .card,
  .pie_graph .card {
    height: auto !important;
  }

  .chart_head {
    padding-bottom: 0 !important;
  }

  .daterangepickercontainer {
    margin-top: 5p;
  }

  .search_filters .btn {
    margin-left: 0 !important;
    margin-bottom: 7px;
  }

  .sidebar__link p {
    top: 74%;
  }

  .addbtn {
    padding: 3px 10px !important;
  }

  .onboard .next {
    font-size: 15px !important;
  }

  .menuslider {
    width: 126px !important;
  }

  .account__wrapper {
    width: 100% !important;
  }

  .account__card {
    padding: 80px 2% !important;
  }

  .account__card_inner {
    padding: 25px 0px !important;
  }

  .account__forgot-password {
    padding-right: 20px !important;
  }

  .signupbtns_active {
    width: 90% !important;
    margin: 0 5% !important;
  }

  .branding_whole_left,
  .branding_whole_right {
    padding: 0 !important;
  }

  .branding_left h4,
  .branding_right h4 {
    font-size: 11px;
  }

  .branding_left,
  .branding_right {
    width: 50%;
  }

  .rt-tbody img {
    width: 25px;
  }

  .video_btn {
    margin-top: 10px !important;
  }

  .ReactModal__Content h2 {
    font-size: 22px;
  }

  .agreement span {
    margin: 5px 0px !important;
  }

  .form_branding_left,
  .form_branding_right {
    width: 100% !important;
    float: left;
    padding: 0;
    margin-top: 10px;
  }

  .addbuttons {
    float: right !important;
  }

  .Break_down {
    width: 100%;
    padding-bottom: 20px;
  }

  .Break_downs {
    width: 100%;
    float: right;
    border: solid 2px #ccc;
    margin-top: 15px;
  }

  .left_summary,
  .right_summary {
    font-size: 12px !important;
  }

  .Break_downs h4,
  .Break_down h4 {
    font-size: 15px;
  }

  .Break_downs .rt-resizable-header-content {
    font-size: 12px;
  }

  .tabs_market {
    padding: 5px;
  }

  .bold-text {
    padding: 0 !important;
  }

  .add-product-csv {
    float: right;
  }

  .add-product-csv button {
    margin-top: 28px;
  }

  .addbtn {
    margin-top: 10px;
    margin-left: 0 !important;
  }

  .add_width {
    width: 100%;
  }

  .addbtn_guide {
    padding: 6px !important;
    margin-right: 10px;
  }

  .form_footer button {
    margin-left: 10px !important;
  }

  .dashboard_middle h2 {
    padding: 8px 5px 0px 5px;
  }

  .search_filters {
    flex: 0 0 31% !important;
    max-width: 31% !important;
    margin: 1%;
  }

  .right_side_sales {
    right: 0;
  }

  .right_side_sales h3 {
    font-size: 14px;
  }

  .product_bold-text,
  .product_bold-text button {
    width: 100%;
  }

  .for_web {
    display: none;
  }

  .for_mobile {
    display: block;
  }

  .search_shipping {
    padding: 0 !important;
  }

  .search_button {
    padding: 3.5px;
  }

  .shipping_head_second {
    padding: 10px !important;
  }

  .shipping_head_second div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .order_summary_inner_div span img {
    padding-left: 5px;
  }

  .tablehead div,
  .tablebody div {
    width: 33.33%;
    float: left;
    text-align: center;
    padding: 0 2px !important;
  }

  .order_header .order_item {
    width: 55%;
    float: left;
    font-weight: bold;
    padding: 5px;
    font-size: 12px;
    text-align: center;
  }

  .order_header .order_unit {
    width: 14%;
    float: left;
    font-weight: bold;
    padding: 5px;
    font-size: 12px;
    text-align: center;
  }

  .order_header .order_qty {
    width: 15%;
    float: left;
    font-weight: bold;
    padding: 5px;
    font-size: 12px;
    text-align: center;
  }

  .order_header .order_total {
    width: 16%;
    float: left;
    font-weight: bold;
    padding: 5px 0;
    font-size: 12px;
    text-align: center;
  }

  .order_body .order_item {
    width: 55%;
    float: left;
    padding: 5px;
    font-size: 12px;
    text-align: center;
  }

  .order_body .order_unit {
    width: 14%;
    float: left;
    padding: 5px;
    font-size: 12px;
    text-align: center;
  }

  .order_body .order_qty {
    width: 15%;
    float: left;
    padding: 5px;
    font-size: 12px;
    text-align: center;
  }

  .order_body .order_total {
    width: 16%;
    float: left;
    padding: 5px;
    font-size: 12px;
    text-align: center;
  }

  .order_summary h2 {
    font-size: 13px;
    padding: 3px 6px;
    font-weight: bold;
  }

  .ship_btn {
    margin: 5px 1px;
  }

  .order_summary_inner .form-check-input {
    float: left;
    width: 20px !important;
    position: relative;
    margin: 3px;

  }

  .activity {
    width: calc(100% - 20px);
    margin: 10px;
  }

  .activity_date {
    font-size: 12px;
  }

  .activity .line {
    right: 7px;
  }

  .dot_header {
    font-size: 13px;
    margin-left: 5px;
  }

  .ordernotes {
    padding: 4px 8px;
  }

  .shipping_info .activity_date {
    font-size: 12px;
  }

  .shipping_info .activity_date {
    line-height: 21px;
  }

  .shipping_info .shippingsize input {
    width: calc(49% - 60px) !important;
  }

  .product_variant_div .variant_div_inner {
    width: 100% !important;
  }

  .variant_div_inner ul li {
    width: 91% !important;
  }

  .sugges {
    width: 100%;
    margin: 4% 0;
  }

  .ReactModal__Content--after-open {
    width: 95% !important;
    padding: 10px !important;
  }

  .ReactModal__Content--after-open .css-1dkwqii {
    width: 100%;
  }

  .editimage .btn {
    margin-top: 0px !important;
  }

  .product_variant_div .name_inner .col {
    width: 50% !important;
  }

  .identifiers_tabs .react-tabs__tab-list span {
    width: 73%;
  }

  .name_inner span {
    width: 100% !important;
  }

  .tabs_market_div {
    margin: 35px 0px;
  }

  .purchase {
    padding: 3px 10px;
    font-size: 14px;
  }

  .storefront_design_div h1 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 20px;
  }

  .storefront_design_div_inner {
    padding: 20px 0;
  }

  .storefront_design_div_inner h4 {
    font-size: 14px;
    padding-bottom: 10px;
  }

  .title_top {
    font-size: 20px;
    margin: 23px 0 10px 0;
  }

  .title_top_heading {
    font-size: 15px;
    margin: 10px 0;
  }

  .storefront_design_div_inner .li {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .subscription_div span {
    padding-left: 0;
  }

  .tax_tab .form__form-group-field {
    height: 68px !important;
    width: 100% !important;
  }

  .form_footer button {
    margin: 20px 10px !important;
    width: calc(100% - 20px) !important;
  }

  .w-9-upload {
    width: 160px !important;
    margin-top: 10px;
  }




  .overlayIntro.resizePop .react-user-tour-container>div:first-child {
    width: 200px !important;
    height: 171px !important;
    // transform: translate3d(4px, 295px, 0px)!important;
    margin-left: 85px !important;
    font-size: 11px !important;
  }

  .overlayIntro.productpop2 .react-user-tour-container>div:first-child {
    width: 200px !important;
    height: 171px !important;
    // transform: translate3d(4px, 295px, 0px)!important;
    margin-left: 85px !important;
    font-size: 11px !important;
  }

  .overlayIntro.resizePop .react-user-tour-container>div:first-child div {
    font-size: 11px !important;
  }

  .react-user-tour-next-button {
    font-size: 11px;
  }


  .react-user-tour-container div {
    font-size: 11px !important;
  }

  .react-user-tour-container>div:first-child {
    width: 316px !important;
  }

  .react-user-tour-container {
    z-index: 99999 !important;
  }

  .from_to1 {
    z-index: 99 !important;
  }

  .overlayIntro.resizeSalesPop {
    .react-user-tour-container>div:first-child {
      width: 316px !important;
    }
  }

  .alignTop+.overlayIntro {
    left: 4px !important;
  }

}

.react-user-tour-container {
  z-index: 99999 !important;
}

.product_orange {
  background: orange !important;
  color: black !important;
}